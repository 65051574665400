import { useContext, useEffect, useMemo } from "react";
import OnboardingStep, { PaginationItem } from "../components/OnboardingStep";
import { IntegrationsContext } from "../providers/IntegrationsProvider";
import { IntegrationType } from "../types";
import { getOnboardingRoute } from "../utils";

const DATA_LABEL_LOOKUP: { [key in IntegrationType]: string } = {
  budget: "pulling in your transactions and account info",
  stocks: "syncing your stock ticker data.",
};

export default function OnboardingIntegrationSuccess({
  integrationType,
  hero,
}: {
  integrationType: IntegrationType;
  hero: React.ReactNode;
}) {
  const {
    state: { loading },
    currentIntegration,
    updateOnboardingState,
  } = useContext(IntegrationsContext);

  const integration = currentIntegration(integrationType);
  useEffect(() => {
    if (!integration || integration.onboardingState === "integration-success") {
      return;
    }

    updateOnboardingState(integration.id, "integration-success");
  }, [integration]);

  const NEXT_PAGINATION_ITEM = useMemo<PaginationItem>(() => {
    return {
      label: "Subscription",
      path: getOnboardingRoute({
        type: integrationType,
        onboardingState: "billing",
      }),
      disabled: !loading && !integration,
    };
  }, [integration, loading]);

  return (
    <OnboardingStep
      title="Great Success!"
      description={`Your Notion workspace is all set up and we are ready to start ${DATA_LABEL_LOOKUP[integrationType]}.`}
      prev={null}
      next={NEXT_PAGINATION_ITEM}
      hero={hero}
    ></OnboardingStep>
  );
}
