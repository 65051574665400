import OnboardingStep from "../components/OnboardingStep";
import IntegrationPreview from "../components/IntegrationPreview";
import { CLIENT_CONFIG } from "../constants";

export default function OnboardingWelcome() {
  return (
    <OnboardingStep
      title={`Welcome to ${CLIENT_CONFIG.appLabel}!`}
      description={CLIENT_CONFIG.appDescription}
      prev={null}
      next={null}
      hero={<CLIENT_CONFIG.images.compositeWelcome />}
      maxWidth={1000}
    >
      <div className="mt-4 u-flex u-justify-center u-align-flex-start">
        {CLIENT_CONFIG.integrationConfig["budget"].enabled && (
          <IntegrationPreview integrationType="budget" className="mr-2" />
        )}
        {CLIENT_CONFIG.integrationConfig["stocks"].enabled && (
          <IntegrationPreview integrationType="stocks" />
        )}
      </div>
    </OnboardingStep>
  );
}
