import React from "react";
import { CLIENT_CONFIG } from "../constants";
export default function BaseNav({
  children,
  onMenuClick,
  logo,
}: {
  children?: React.ReactNode;
  onMenuClick: () => void;
  logo?: string;
}) {
  return (
    <div className="header header-fixed u-unselectable header-animated">
      <div className="header-brand">
        <div className="nav-item no-hover u-flex u-items-center">
          <img
            src={logo || CLIENT_CONFIG.images.wordmarkLogo}
            width={100}
            className="mr-1"
          />
        </div>
        <div className="nav-item nav-btn" id="header-btn" onClick={onMenuClick}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      {children}
    </div>
  );
}
