import axios from "axios";
import React, {
  useReducer,
  useCallback,
  useState,
  Reducer,
  useEffect,
} from "react";
import useAuth from "../hooks/useAuth";
import { BatchJobJSON, GetBatchJobsResponse, JobStatus } from "../types";
import { createRequestConfig } from "../utils";

export interface BatchJobState {
  jobs: { [jobId: string]: BatchJobJSON };
  loading: boolean;
  pollingLookup: { [jobId: string]: boolean };
}

export interface BatchJobContextValue {
  state: BatchJobState;
  getJobs: (jobIds: Array<string>) => void;
}

type BatchJobAction = { type: "GET_JOBS"; jobs: Array<BatchJobJSON> };

const noop = () => {};
const POLL_INTERVAL = 30000;

export const BatchJobContext = React.createContext<BatchJobContextValue>({
  state: { jobs: {}, loading: false, pollingLookup: {} },
  getJobs: noop,
});

const Provider = ({ children }: { children: React.ReactNode }) => {
  const { getAccessTokenSilently } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [pollingLookup, setPollingLookup] = useState<{
    [jobId: string]: boolean;
  }>({});

  const [state, dispatch] = useReducer<Reducer<BatchJobState, BatchJobAction>>(
    (state, action) => {
      const nextState: BatchJobState = {
        ...state,
        jobs: { ...state.jobs },
        loading,
      };

      switch (action.type) {
        case "GET_JOBS": {
          const jobLookup = action.jobs.reduce<Record<string, BatchJobJSON>>(
            (lookup, job) => {
              lookup[job.id] = job;
              return lookup;
            },
            {}
          );

          nextState.jobs = { ...nextState.jobs, ...jobLookup };
          return nextState;
        }
      }
    },
    { jobs: {}, loading, pollingLookup: {} }
  );

  const getJobs = useCallback(
    async (jobIds: Array<string>) => {
      if (!jobIds.length) {
        return;
      }

      setLoading(true);

      const res = await axios.post<GetBatchJobsResponse>(
        "/get-batch-jobs",
        { jobIds },
        createRequestConfig(await getAccessTokenSilently())
      );

      if (!res.data.success) {
        console.error("Unable to get batch jobs.");
        return;
      }

      dispatch({ type: "GET_JOBS", jobs: res.data.jobs });
      setLoading(false);
    },
    [dispatch, getAccessTokenSilently]
  );

  const value: BatchJobContextValue = {
    state: { ...state, loading, pollingLookup },
    getJobs,
  };

  // Handle automated polling anytime job status is not terminal.
  useEffect(() => {
    if (state.loading) {
      return;
    }

    const jobIdsToPoll: Array<string> = Object.values(state.jobs).reduce<
      Array<string>
    >((res, job) => {
      if (![JobStatus.FAILED, JobStatus.SUCCEEDED].includes(job.status)) {
        res.push(job.id);
      }

      return res;
    }, []);

    if (jobIdsToPoll.length) {
      setPollingLookup((prev) =>
        jobIdsToPoll.reduce(
          (res, jobId) => {
            res[jobId] = true;
            return res;
          },
          { ...prev }
        )
      );
      window.setTimeout(async () => {
        await getJobs(jobIdsToPoll);
      }, POLL_INTERVAL);
    } else {
      setPollingLookup({});
    }
  }, [state, getJobs]);

  return (
    <BatchJobContext.Provider value={value}>
      {children}
    </BatchJobContext.Provider>
  );
};

export default Provider;
