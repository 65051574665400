import { useCallback, useContext, useEffect } from "react";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import OnboardingStep, { PaginationItem } from "../components/OnboardingStep";
import { UserInfoContext } from "../providers/UserInfoProvider";
import { getOnboardingRoute } from "../utils";
import { IntegrationsContext } from "../providers/IntegrationsProvider";
import { CLIENT_CONFIG } from "../constants";

const MAX_DATE: string = new Date().toISOString().split("T")[0];
const PREV_PAGINATION_ITEM: PaginationItem = {
  label: "Subscription",
  path: getOnboardingRoute({ type: "budget", onboardingState: "billing" }),
};

const NEXT_PAGINATION_ITEM: PaginationItem = {
  label: "Add Accounts",
  path: getOnboardingRoute({ type: "budget", onboardingState: "accounts" }),
};

export default function OnboardingPreferences() {
  const {
    state: { currentUser },
    updateUserInfo,
  } = useContext(UserInfoContext);
  const { currentIntegration, updateOnboardingState } =
    useContext(IntegrationsContext);

  useEffect(() => {
    const integration = currentIntegration("budget", {
      discoveryComplete: true,
    });
    if (!integration || integration.onboardingState === "preferences") {
      return;
    }

    updateOnboardingState(integration.id, "preferences");
  }, [currentIntegration]);

  const handleStartDateChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateUserInfo({
        transactionStartDate: e.target.value
          ? utcToZonedTime(
              e.target.value,
              Intl.DateTimeFormat().resolvedOptions().timeZone
            ).valueOf()
          : null,
      });
    },
    [updateUserInfo]
  );

  return (
    <OnboardingStep
      title="Your preferences"
      description="Tweak the below settings to your liking (or don't, the defaults will work just fine). You can also set these preferences from your dashboard."
      prev={PREV_PAGINATION_ITEM}
      next={NEXT_PAGINATION_ITEM}
      hero={
        <CLIENT_CONFIG.integrationConfig.budget.budgetImages.onboardingPreferences />
      }
    >
      <div className="divider" />
      <div className="u-flex u-flex-column u-items-flex-start mb-2">
        <p className="m-0 mb-1 text-md font-semibold">
          Earliest Transaction Date:
        </p>
        <p className="m-0 text-sm u-text-left mb-1">
          Any transaction data before this date will not be imported into your
          notion workspace (max/default: 24 months).
        </p>

        <input
          type="date"
          max={MAX_DATE}
          value={
            currentUser?.transactionStartDate
              ? format(new Date(currentUser.transactionStartDate), "yyyy-MM-dd")
              : undefined
          }
          onChange={handleStartDateChange}
        />
      </div>
    </OnboardingStep>
  );
}
