import { useCallback, useState } from "react";
import classnames from "classnames";
import BaseNav from "./BaseNav";
import useAuth from "../hooks/useAuth";

export default function LandingNav() {
  const [menuActive, setMenuActive] = useState(false);
  const { loginWithRedirect } = useAuth();
  const handleLogin = useCallback(() => {
    loginWithRedirect();
  }, []);

  const handleMenuClick = useCallback(() => {
    setMenuActive((prev) => !prev);
  }, []);

  return (
    <BaseNav onMenuClick={handleMenuClick}>
      <div
        className={classnames("header-nav", menuActive && "active")}
        id="header-menu"
      >
        <div className="nav-right">
          <div className="nav-item">
            <a onClick={handleLogin}>Login</a>
          </div>
        </div>
      </div>
    </BaseNav>
  );
}
