import { AxiosRequestConfig } from "axios";
import {
  BaseIntegrationJSON,
  IntegrationType,
  StripeSubscriptionJSON,
  OnboardingState,
} from "./types";
import { NOTION_BASE_OAUTH_URL, CLIENT_CONFIG } from "./constants";

export function createRequestConfig(
  accessToken: string,
  config?: AxiosRequestConfig
): AxiosRequestConfig {
  return {
    ...config,
    headers: {
      ...config?.headers,
      authorization: `Bearer ${accessToken}`,
    },
  };
}

export function isUserSubscriptionValid(
  userSubscription: StripeSubscriptionJSON | null
): boolean {
  return (
    !!userSubscription &&
    userSubscription.subscriptionPeriodEnd > new Date().valueOf()
  );
}

export function isUserSubscribedToIntegration(
  userSubscription: StripeSubscriptionJSON,
  integrationType: IntegrationType
): boolean {
  return (
    userSubscription.items.findIndex(
      (item) => item.integrationType === integrationType
    ) !== -1
  );
}

export function isJobPolling(
  jobId: string | null,
  pollingLookup: { [jobId: string]: boolean }
): boolean {
  return !!jobId && !!pollingLookup[jobId];
}

export function uuidWithoutDashes(uuid: string): string {
  return uuid.replace("-", "");
}

export function isSmallOrLarger(): boolean {
  return window.innerWidth > 640;
}
export function isMediumOrLarger(): boolean {
  return window.innerWidth > 768;
}
export function isLargeOrLarger(): boolean {
  return window.innerWidth > 1023;
}
export function isExtraLarge(): boolean {
  return window.innerWidth > 1279;
}

export function getOAuthUrl(integrationType: IntegrationType): string {
  return `${NOTION_BASE_OAUTH_URL}?client_id=${CLIENT_CONFIG.integrationConfig[integrationType].notionClientId}&response_type=code&owner=user&redirect_uri=${CLIENT_CONFIG.clientDomain}/${CLIENT_CONFIG.integrationConfig[integrationType].notionOauthRoute}`;
}

export function getPathPortionForOnboardingState(
  onboardingState: OnboardingState
): string {
  switch (onboardingState) {
    case "welcome": {
      return "onboarding/welcome";
    }
    case "integration": {
      return "onboarding/integration";
    }
    case "integration-success": {
      return "onboarding/integration-success";
    }
    case "billing": {
      return "onboarding/billing";
    }
    case "billing-success": {
      return "onboarding/billing-success";
    }
    case "preferences": {
      return "onboarding/preferences";
    }
    case "accounts": {
      return "onboarding/accounts";
    }
    case "complete": {
      return "onboarding/finished";
    }
  }
}

/**
 * Get route path for specified onboarding state / integration type.
 */
export function getOnboardingRoute({
  type,
  onboardingState,
}: Pick<BaseIntegrationJSON, "type" | "onboardingState">): string {
  return `${
    CLIENT_CONFIG.integrationConfig[type].clientRoutePrefix
  }/${getPathPortionForOnboardingState(onboardingState)}`;
}

export function getDashboardRoute({ type }: { type: IntegrationType }): string {
  return `${CLIENT_CONFIG.integrationConfig[type].clientRoutePrefix}/dashboard`;
}
