import { useCallback, useContext } from "react";
import { IntegrationsContext } from "../providers/IntegrationsProvider";
import IntegrationRow from "./IntegrationRow";
import StepCard from "./DashboardCard";
import { getOAuthUrl } from "../utils";
import { BudgetIntegrationJSON, IntegrationType } from "../types";

interface IntegrationCardProps {
  integrationType: IntegrationType;
  icon: React.ReactNode;
}
export default function IntegrationCard({
  integrationType,
  icon,
}: IntegrationCardProps) {
  const {
    state: { loading },
    deleteIntegration,
    currentIntegration,
  } = useContext(IntegrationsContext);

  const handleClick = useCallback(() => {
    window.location.href = getOAuthUrl(integrationType);
  }, []);

  const handleDelete = useCallback(
    (integrationId: string) => {
      deleteIntegration(integrationId);
    },
    [deleteIntegration]
  );
  const integration = currentIntegration(integrationType);

  return (
    <>
      <StepCard
        integrationType={integrationType}
        image={icon}
        title="Notion Workspace"
        subtitle="Manage your currently linked Notion workspace."
        buttonContent={
          !loading && !integration ? (
            <>
              <i className="ri-links-line mr-1 text-md"></i>Connect your
              workspace
            </>
          ) : undefined
        }
        onButtonClick={handleClick}
      >
        <div className="my-2 u-overflow-visible">
          {!!integration && (
            <IntegrationRow
              integration={integration as BudgetIntegrationJSON}
              onDelete={handleDelete}
            />
          )}
        </div>
      </StepCard>
    </>
  );
}
