import { useCallback, useContext } from "react";
import { IntegrationType, StripeSubscriptionJSON } from "../types";
import DashboardCard from "./DashboardCard";
import { CLIENT_CONFIG } from "../constants";
import { UserInfoContext } from "../providers/UserInfoProvider";

interface SubscriptionCardProps {
  userSubscription: StripeSubscriptionJSON;
}

export default function SubscriptionCard({
  userSubscription,
}: SubscriptionCardProps) {
  const { createBillingSession } = useContext(UserInfoContext);

  const handleManageSubscriptionClick = useCallback(() => {
    createBillingSession();
  }, []);
  return (
    <DashboardCard
      integrationType={"budget"}
      image={<CLIENT_CONFIG.images.billing.subscriptionPanel />}
      title="Subscription"
      subtitle="Manage your subscription, payment method, etc."
      buttonContent={<>Manage</>}
      onButtonClick={handleManageSubscriptionClick}
    ></DashboardCard>
  );
}
