import { useCallback, useMemo } from "react";
import styled from "styled-components";
import { BudgetIntegrationJSON } from "../types";
import { uuidWithoutDashes } from "../utils";
import DeleteConfirmationModal from "./modals/DeleteConfirmationModal";

interface IntegrationRowProps {
  integration: BudgetIntegrationJSON;
  onDelete: (integrationId: string) => void;
}

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: visible;
`;

const Col1 = styled.div`
  display: flex;
  align-items: center;
`;
const Col2 = styled.div``;

export default function IntegrationRow({
  integration: { workspaceIcon, rootPageUrl, workspaceName, id },
  onDelete,
}: IntegrationRowProps) {
  const handleDelete = useCallback(() => {
    onDelete(id);
  }, [onDelete, id]);

  const modalId: string = `unlink-integration-${id}`;

  return (
    <>
      <Row className="animated fadeIn">
        <Col1>
          <img
            style={{ height: 20 }}
            width={20}
            height={20}
            src={
              workspaceIcon ||
              "https://upload.wikimedia.org/wikipedia/commons/4/45/Notion_app_logo.png"
            }
          />
          <a
            href={rootPageUrl || "#"}
            target="blank"
            className="text-md font-semibold my-1 ml-1 u-no-wrap u-flex u-items-center"
            style={{ color: "#0f172a" }}
          >
            {workspaceName || "Unnamed Workspace"}
            <i className="ri-links-line pl-1"></i>
          </a>
        </Col1>
        <Col2 className="col-xs-2 ignore-screen u-flex u-items-center u-justify-flex-end">
          <a
            href={`#${modalId}`}
            className="btn--xs btn-transparent m-0 tooltip u-center u-inline-flex"
            data-tooltip="Disconnect"
          >
            <i className="ri-delete-bin-line text-md"></i>
          </a>
        </Col2>
      </Row>
      <DeleteConfirmationModal
        modalId={modalId}
        title="Disconnect Workspace?"
        onConfirm={handleDelete}
      >
        Are you sure you want to disconnect your Notion workspace?
      </DeleteConfirmationModal>
    </>
  );
}
