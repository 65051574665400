import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import { ENVIRONMENT } from "../constants";

function getFullPath(): string {
  return (
    window.location.pathname + window.location.search + window.location.hash
  );
}

if (ENVIRONMENT === "production") {
  ReactGA.initialize("G-NTJ1WJRF59", {
    gtagOptions: { page_title: getFullPath(), page_location: getFullPath() },
  });
}

export default function AnalyticsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const location = useLocation();
  useEffect(() => {
    ReactGA.set({ page: location.pathname });
    // ReactGA.send({})
  }, [location.pathname]);
  return <>{children}</>;
}
