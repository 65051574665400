import axios, { AxiosResponse } from "axios";
import React, {
  useReducer,
  useCallback,
  useState,
  Reducer,
  useContext,
  useMemo,
} from "react";
import useAuth from "../hooks/useAuth";
import {
  GetIntegrationsResponse,
  BaseIntegrationJSON,
  PostNotionOAuthResponse,
  IntegrationType,
  OnboardingState,
  UpdateOnboardingStateResponse,
  IntegrationPayloadType,
} from "../types";
import { createRequestConfig } from "../utils";
import { BannerContext } from "./BannerProvider";
import { CLIENT_CONFIG } from "../constants";

export type IntegrationStatus = "non-existent" | "incomplete" | "completed";

export interface IntegrationsState {
  integrations: Array<BaseIntegrationJSON>;
  deletedIntegrations: Array<BaseIntegrationJSON>;
  loading: boolean;
  budgetIntegrationStatus: IntegrationStatus;
  stocksIntegrationStatus: IntegrationStatus;
  hasSomeCompletedIntegration: boolean;
}

export interface IntegrationsContextValue {
  state: IntegrationsState;
  getIntegrations: (includeTransactionCategories: boolean) => void;
  createIntegration: (
    oAuthCode: string,
    integrationType: IntegrationType
  ) => void;
  deleteIntegration: (integrationId: string) => void;
  updateOnboardingState: (
    integrationId: string,
    onboardingState: OnboardingState
  ) => void;
  currentIntegration: <T extends IntegrationType>(
    integrationType: T,
    options?: { discoveryComplete?: boolean }
  ) => IntegrationPayloadType<T> | null;
}

type IntegrationsAction =
  | { type: "GET_INTEGRATIONS"; integrations: Array<BaseIntegrationJSON> }
  | { type: "UPDATE_INTEGRATION"; integration: BaseIntegrationJSON }
  | { type: "DELETE_INTEGRATION"; integrationId: string }
  | {
      type: "UPDATE_ONBOARDING_STATE";
      integrationId: string;
      onboardingState: OnboardingState;
    }
  | { type: "RESTORE_INTEGRATION"; integrationId: string };

const noop = () => {};

export const IntegrationsContext =
  React.createContext<IntegrationsContextValue>({
    state: {
      integrations: [],
      deletedIntegrations: [],
      loading: false,
      hasSomeCompletedIntegration: false,
      budgetIntegrationStatus: "non-existent",
      stocksIntegrationStatus: "non-existent",
    },
    getIntegrations: noop,
    createIntegration: noop,
    deleteIntegration: noop,
    updateOnboardingState: noop,
    currentIntegration: () => null,
  });

const Provider = ({ children }: { children: React.ReactNode }) => {
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth();
  const { pushBanners } = useContext(BannerContext);

  const [state, dispatch] = useReducer<
    Reducer<IntegrationsState, IntegrationsAction>
  >(
    (state, action) => {
      const nextState: IntegrationsState = {
        ...state,
        integrations: [...state.integrations],
        deletedIntegrations: [...state.deletedIntegrations],
      };

      switch (action.type) {
        case "GET_INTEGRATIONS": {
          nextState.integrations = action.integrations;
          return nextState;
        }
        case "UPDATE_INTEGRATION": {
          const targetIndex: number = nextState.integrations.findIndex(
            (item) => item.id === action.integration.id
          );
          if (targetIndex === -1) {
            nextState.integrations = [
              ...nextState.integrations,
              action.integration,
            ];
          } else {
            nextState.integrations.splice(targetIndex, 1, action.integration);
            nextState.integrations = [...nextState.integrations];
          }
          return nextState;
        }
        case "DELETE_INTEGRATION": {
          const targetIndex: number = nextState.integrations.findIndex(
            (item) => item.id === action.integrationId
          );

          if (targetIndex === -1) {
            return state;
          }

          const deletedIntegrations = nextState.integrations.splice(
            targetIndex,
            1
          );
          nextState.deletedIntegrations = [
            ...nextState.deletedIntegrations,
            ...deletedIntegrations,
          ];
          return nextState;
        }
        case "RESTORE_INTEGRATION": {
          const integrationToRestore: BaseIntegrationJSON | undefined =
            nextState.deletedIntegrations.find(
              (integration) => integration.id === action.integrationId
            );
          if (integrationToRestore) {
            nextState.integrations.push(integrationToRestore);
          }

          return nextState;
        }
        case "UPDATE_ONBOARDING_STATE": {
          const targetIndex: number = nextState.integrations.findIndex(
            (item) => item.id === action.integrationId
          );

          if (targetIndex === -1) {
            return state;
          }

          nextState.integrations[targetIndex].onboardingState =
            action.onboardingState;
          nextState.integrations = [...nextState.integrations];
          return nextState;
        }
      }
    },
    {
      integrations: [],
      deletedIntegrations: [],
      loading: false,
      hasSomeCompletedIntegration: false,
      budgetIntegrationStatus: "non-existent",
      stocksIntegrationStatus: "non-existent",
    }
  );

  const getIntegrations = useCallback(
    async (includeTransactionCategories: boolean) => {
      setLoading(true);
      const accessToken = await getAccessTokenSilently();
      const res: AxiosResponse<GetIntegrationsResponse> = await axios.get(
        "/integrations",
        createRequestConfig(accessToken, {
          params: { includeTransactionCategories },
        })
      );

      if (!res.data.success) {
        console.error("Unable to get integrations.");
        return;
      }

      dispatch({
        type: "GET_INTEGRATIONS",
        integrations: res.data.integrations,
      });
      setLoading(false);
    },
    [dispatch, getAccessTokenSilently]
  );

  const createIntegration = useCallback(
    async (oAuthCode: string, integrationType: IntegrationType) => {
      const accessToken = await getAccessTokenSilently();
      const res: AxiosResponse<PostNotionOAuthResponse> = await axios.post(
        "/notion-oauth",
        { code: oAuthCode, integrationType },
        createRequestConfig(accessToken)
      );

      if (!res.data.success) {
        console.error("Notion OAuth error occurred.");
        return;
      }

      dispatch({
        type: "UPDATE_INTEGRATION",
        integration: res.data.integration,
      });
    },
    [dispatch, getAccessTokenSilently]
  );

  const deleteIntegration = useCallback(
    async (integrationId: string) => {
      dispatch({ type: "DELETE_INTEGRATION", integrationId });
      const accessToken = await getAccessTokenSilently();
      const res: AxiosResponse<PostNotionOAuthResponse> = await axios.post(
        "/delete-integration",
        { integrationId },
        createRequestConfig(accessToken)
      );

      if (!res.data.success) {
        pushBanners([
          {
            dismissible: true,
            type: "danger",
            text: "Unable to disconnect integration at this time. Please try again later.",
          },
        ]);
        dispatch({ type: "RESTORE_INTEGRATION", integrationId });
        return;
      }

      pushBanners([
        {
          dismissible: true,
          type: "success",
          text: "Integration was successfully disconnected.",
        },
      ]);
    },
    [dispatch, getAccessTokenSilently, pushBanners]
  );

  const updateOnboardingState = useCallback(
    async (integrationId: string, onboardingState: OnboardingState) => {
      const accessToken = await getAccessTokenSilently();
      const res: AxiosResponse<UpdateOnboardingStateResponse> =
        await axios.post(
          "/update-onboarding-state",
          { integrationId, onboardingState },
          createRequestConfig(accessToken)
        );

      if (res.data.success) {
        dispatch({
          type: "UPDATE_ONBOARDING_STATE",
          integrationId,
          onboardingState: res.data.onboardingState,
        });
      }
    },
    [dispatch, getAccessTokenSilently]
  );

  const currentIntegration = useCallback(
    <T extends IntegrationType>(
      integrationType: T,
      { discoveryComplete }: { discoveryComplete?: boolean } = {}
    ): IntegrationPayloadType<T> | null => {
      return (
        state.integrations
          .filter(
            CLIENT_CONFIG.integrationConfig[integrationType].typeguardFilter
          )
          .find(
            (integration) =>
              !discoveryComplete ||
              integration.template.discoveryState === "COMPLETE"
          ) || null
      );
    },
    [state.integrations]
  );

  const budgetIntegrationStatus = useMemo<IntegrationStatus>(
    () => getIntegrationStatus("budget", state.integrations),
    [state.integrations]
  );
  const stocksIntegrationStatus = useMemo<IntegrationStatus>(
    () => getIntegrationStatus("stocks", state.integrations),
    [state.integrations]
  );
  const hasSomeCompletedIntegration = useMemo<boolean>(
    () =>
      !!state.integrations.find(
        (integration) => integration.onboardingState === "complete"
      ),
    [state.integrations]
  );

  const value: IntegrationsContextValue = {
    state: {
      ...state,
      loading,
      hasSomeCompletedIntegration,
      budgetIntegrationStatus,
      stocksIntegrationStatus,
    },
    getIntegrations,
    deleteIntegration,
    createIntegration,
    currentIntegration,
    updateOnboardingState,
  };

  return (
    <IntegrationsContext.Provider value={value}>
      {children}
    </IntegrationsContext.Provider>
  );
};

function getIntegrationStatus(
  integrationType: IntegrationType,
  integrations: Array<BaseIntegrationJSON>
): IntegrationStatus {
  const targetIntegration = integrations.find(
    (integration) => integration.type === integrationType
  );

  if (!targetIntegration) {
    return "non-existent";
  }
  return targetIntegration.onboardingState === "complete"
    ? "completed"
    : "incomplete";
}

export default Provider;
