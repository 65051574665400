import { useContext, useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import OnboardingStep from "../components/OnboardingStep";
import { IntegrationsContext } from "../providers/IntegrationsProvider";
import { IntegrationType } from "../types";
import { getOnboardingRoute } from "../utils";

const INTEGRATION_POLL_INTERVAL: number = 10000;
const ESTIMATED_COMPLETION_TIME_MS: number = 1000 * 180 * 2;

const LOADING_MESSAGES: Array<string> = [
  "Waiting for Notion to duplicate our template into your workspace... Head's up this usually takes a few minutes to complete.",
  "Waiting for Notion to duplicate our template into your workspace... Head's up this usually takes a few minutes to complete.",
  "Waiting for Notion to duplicate our template into your workspace... Head's up this usually takes a few minutes to complete.",
  "Waiting for Notion to duplicate our template into your workspace... Head's up this usually takes a few minutes to complete.",
  "Waiting for Notion to duplicate our template into your workspace... Head's up this usually takes a few minutes to complete.",
  "Waiting for Notion to duplicate our template into your workspace... Head's up this usually takes a few minutes to complete.",
  "Taking note of your template page IDs...",
  "Scanning your NoFi databases for property IDs...",
  "Waiting for Notion to recognize your new template...",
  "Notion is being slow... Don't worry we're used to it.",
  "Notion is being slow... Don't worry we're used to it.",
  "We'll ask again and say please this time...",
  "We'll ask again and say please this time...",
  "Wherefore art thou, Notion? 😿",
  "Wherefore art thou, Notion? 😿",
  "Looks like Notion search is taking a while to index your new template...",
  "Looks like Notion search is taking a while to index your new template...",
  "Looks like Notion search is taking a while to index your new template...",
  "This is getting embarrasing 😳. Wouldn't it be great if Notion search worked better... We think so 🙃",
  "This is getting embarrasing 😳. Wouldn't it be great if Notion search worked better... We think so 🙃",
  "This is getting embarrasing 😳. Wouldn't it be great if Notion search worked better... We think so 🙃",
  "Thanks for your patience. We're running on hopes and dreams at this point...",
  "Thanks for your patience. We're running on hopes and dreams at this point...",
  "Thanks for your patience. We're running on hopes and dreams at this point...",
  "Well this is awkward 🙈. For reason's unknown to us, Notion's search engine takes a very long time to index duplicated sub-pages when their site gets busy. We'll continue trying until we get everything working. Check back in ~15 minutes. If your template is still not discovered, please reach out to our support team and we'll get things fixed up for you.",
];

// Returns [0, 100] based on loader progress + est. completion time.
function getLoadingMessageIndex(startTimestamp: number): number {
  const elapsed: number = new Date().valueOf() - startTimestamp;

  return Math.max(
    Math.min(
      Math.floor(
        (elapsed / ESTIMATED_COMPLETION_TIME_MS) * LOADING_MESSAGES.length - 1
      ),
      LOADING_MESSAGES.length - 1
    ),
    0
  );
}

function getLoaderProgress(startTimestamp: number): number {
  const elapsed: number = new Date().valueOf() - startTimestamp;
  // shouldn't happen.
  if (elapsed < 0) {
    return 0;
  }

  return Math.min((elapsed / ESTIMATED_COMPLETION_TIME_MS) * 100, 100);
}

export default function IntegrationInit({
  integrationType,
  hero,
}: {
  integrationType: IntegrationType;
  hero: React.ReactNode;
}) {
  const {
    state: { integrations, loading },
    currentIntegration,
    createIntegration,
    getIntegrations,
    updateOnboardingState,
  } = useContext(IntegrationsContext);

  const navigate = useNavigate();
  const [progress, setProgress] = useState<number>(0);
  const [loadingMessageIndex, setLoadingMessageIndex] = useState<number>(0);

  const integrationCreated = useRef(false);
  const startTimestamp = useRef<number | null>(null);
  const progressIntervalId = useRef<number | null>(null);

  useEffect(() => {
    if (loading) {
      return;
    }

    // user has an integration with completed discovery. redirect to home.
    const completedIntegration = currentIntegration(integrationType, {
      discoveryComplete: true,
    });
    if (completedIntegration) {
      setProgress(100);
      setLoadingMessageIndex(-1);
      progressIntervalId.current &&
        window.clearInterval(progressIntervalId.current);

      setTimeout(() => {
        navigate(
          getOnboardingRoute({
            type: integrationType,
            onboardingState: "integration-success",
          })
        );
      }, 2000);
      return;
    }

    // If start time for loader has not been initialized, do so now.
    if (startTimestamp.current === null) {
      startTimestamp.current = new Date().valueOf();
      progressIntervalId.current = window.setInterval(() => {
        setProgress(getLoaderProgress(startTimestamp.current as number));
        setLoadingMessageIndex(
          getLoadingMessageIndex(startTimestamp.current as number)
        );
      }, 200);
    }

    // user has an integration with incomplete discovery. redirect to home.
    if (integrations.length && !completedIntegration) {
      setTimeout(() => {
        getIntegrations(true);
      }, INTEGRATION_POLL_INTERVAL);
      return;
    }

    // User doesn't have notion access token. try to get one.
    if (!integrations.length) {
      const queryParams = new URLSearchParams(window.location.search);
      const code = queryParams.get("code");
      const error = queryParams.get("error");

      // User closed oAuth modal without authorizing.
      if (error === "access_denied") {
        navigate("/");
        return;
      }

      // No code was present on the response, shouldn't happen.
      if (!code) {
        console.log("Unable to find OAuth code... aborting");
        return;
      }

      if (!integrationCreated.current) {
        integrationCreated.current = true;
        createIntegration(code, integrationType);
      }
    }
  }, [integrations, currentIntegration, integrationType]);

  return (
    <div className="u-flex u-justify-center">
      <OnboardingStep
        title="Getting your workspace set up"
        description={
          loadingMessageIndex === -1
            ? "All set! 🎉"
            : LOADING_MESSAGES[loadingMessageIndex]
        }
        prev={null}
        next={null}
        hero={hero}
      >
        <progress
          style={{ maxWidth: 400, margin: "auto" }}
          className="progress progress--md my-2"
          value={`${progress}`}
          max="100"
        ></progress>
      </OnboardingStep>
    </div>
  );
}
