import React, { useReducer, useCallback, Reducer } from "react";
import { v4 as uuidv4 } from "uuid";

import { Banner } from "../types";

export interface BannerState {
  banners: Array<Banner>;
}

export interface BannerContextValue {
  state: BannerState;
  pushBanners: (banners: Array<Omit<Banner, "id">>) => void;
  dismissBanners: (bannerIds: Array<string>) => void;
  clearBanners: () => void;
}

type BannerAction =
  | { type: "PUSH_BANNERS"; banners: Array<Banner> }
  | { type: "DISMISS_BANNERS"; bannerIds: Array<string> }
  | { type: "CLEAR_BANNERS" };

const noop = () => {};

const DEFAULT_BANNER_TTL_MS: number = 8000;

export const BannerContext = React.createContext<BannerContextValue>({
  state: { banners: [] },
  pushBanners: noop,
  dismissBanners: noop,
  clearBanners: noop,
});

const Provider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer<Reducer<BannerState, BannerAction>>(
    (state, action) => {
      const nextState: BannerState = {
        ...state,
        banners: [...state.banners],
      };

      switch (action.type) {
        case "PUSH_BANNERS": {
          nextState.banners.push(...action.banners);
          return nextState;
        }
        case "DISMISS_BANNERS": {
          for (const bannerId of action.bannerIds) {
            const targetIndex: number = nextState.banners.findIndex(
              (banner) => banner.id === bannerId
            );
            if (targetIndex !== -1) {
              nextState.banners.splice(targetIndex, 1);
            }
          }
          return nextState;
        }
        case "CLEAR_BANNERS": {
          nextState.banners = [];
          return nextState;
        }
      }
    },
    {
      banners: [],
    }
  );

  const pushBanners = useCallback(
    (banners: Array<Omit<Banner, "id"> & { id?: string }>) => {
      const bannersWithIds = banners.map((b) => ({
        ...b,
        id: b.id || uuidv4(),
      }));
      dispatch({ type: "PUSH_BANNERS", banners: bannersWithIds });
      bannersWithIds.forEach(({ id, dismissible, ttl }) => {
        if (dismissible) {
          setTimeout(() => {
            dispatch({ type: "DISMISS_BANNERS", bannerIds: [id] });
          }, ttl || DEFAULT_BANNER_TTL_MS);
        }
      });
    },
    [dispatch]
  );

  const dismissBanners = useCallback(
    (bannerIds: Array<string>) => {
      dispatch({ type: "DISMISS_BANNERS", bannerIds });
    },
    [dispatch]
  );

  const clearBanners = useCallback(() => {
    dispatch({ type: "CLEAR_BANNERS" });
  }, [dispatch]);

  const value: BannerContextValue = {
    state,
    pushBanners,
    dismissBanners,
    clearBanners,
  };

  return (
    <BannerContext.Provider value={value}>{children}</BannerContext.Provider>
  );
};

export default Provider;
