import { useContext } from "react";
import SubscriptionCard from "../components/SubscriptionCard";
import { UserInfoContext } from "../providers/UserInfoProvider";
import PaymentMethodCard from "../components/PaymentMethodCard";
import PaymentHistoryCard from "../components/PaymentHistoryCard";

export default function Billing() {
  const {
    state: { currentUser, userSubscription, userSubscriptionLoading },
    createBillingSession,
    createCheckoutSession,
    subscribeToIntegration,
  } = useContext(UserInfoContext);

  if (!userSubscription) {
    return <div className="animated loading m-2" style={{ height: 40 }}></div>;
  }

  return (
    <>
      <SubscriptionCard userSubscription={userSubscription} />
      <PaymentMethodCard userSubscription={userSubscription} />
      <PaymentHistoryCard userSubscription={userSubscription} />
    </>
  );
}
