import {
  AppID,
  BaseIntegrationJSON,
  BudgetIntegrationJSON,
  Environment,
  IntegrationPayloadType,
  IntegrationType,
  StocksIntegrationJSON,
} from "./types";

// nofi app-wide images
import NofiLogo from "./images/nofi/logo.svg";
import NofiWordmark from "./images/nofi/wordmark.svg";
import { ReactComponent as NofiCompositeWelcome } from "./images/nofi/welcome.svg";
import NofiFavicon from "./images/nofi/favicon.png";

// nofi budget images
import { ReactComponent as NofiBudgetDashboardAccountsPanel } from "./images/nofi/budget/dashboard-accounts-panel-icon.svg";
import { ReactComponent as NofiBudgetDashboardIntegrationPanel } from "./images/nofi/budget/dashboard-integration-panel-icon.svg";
import { ReactComponent as NofiBudgetDashboardPreferencesPanel } from "./images/nofi/budget/dashboard-preferences-panel-icon.svg";
import { ReactComponent as NofiBudgetOnboardingAddAccounts } from "./images/nofi/budget/onboarding-add-accounts.svg";
import { ReactComponent as NofiBudgetOnboardingBillingSuccess } from "./images/nofi/budget/onboarding-billing-success.svg";
import { ReactComponent as NofiBudgetOnboardingBilling } from "./images/nofi/budget/onboarding-billing.svg";
import { ReactComponent as NofiBudgetOnboardingDiscoveryInProgress } from "./images/nofi/budget/onboarding-discovery-in-progress.svg";
import { ReactComponent as NofiBudgetOnboardingFinished } from "./images/nofi/budget/onboarding-finished.svg";
import { ReactComponent as NofiBudgetOnboardingIntegrationSuccess } from "./images/nofi/budget/onboarding-integration-success.svg";
import { ReactComponent as NofiBudgetOnboardingIntegration } from "./images/nofi/budget/onboarding-integration.svg";
import { ReactComponent as NofiBudgetOnboardingPreferences } from "./images/nofi/budget/onboarding-preferences.svg";
import { ReactComponent as NofiBudgetOnboardingWelcome } from "./images/nofi/budget/onboarding-welcome.svg";

// nofi stocks images
import NofiStocksLogo from "./images/nofi/stocks/logo.svg";
import NofiStocksWordmark from "./images/nofi/stocks/wordmark.svg";
import { ReactComponent as NofiStocksDashboardDetectedStocksPanel } from "./images/nofi/stocks/dashboard-detected-stocks-panel.svg";
import { ReactComponent as NofiStocksDashboardIntegrationPanel } from "./images/nofi/stocks/dashboard-integration-panel-icon.svg";
import { ReactComponent as NofiStocksOnboardingFinished } from "./images/nofi/stocks/onboarding-finished.svg";
import { ReactComponent as NofiStocksOnboardingDiscoveryInProgress } from "./images/nofi/stocks/onboarding-discovery-in-progress.svg";
import { ReactComponent as NofiStocksOnboardingIntegrationSuccess } from "./images/nofi/stocks/onboarding-integration-success.svg";
import { ReactComponent as NofiStocksOnboardingIntegration } from "./images/nofi/stocks/onboarding-integration.svg";
import { ReactComponent as NofiStocksOnboardingWelcome } from "./images/nofi/stocks/onboarding-welcome.svg";

// nofi billing images
import { ReactComponent as NofiBillingSubscriptionPanel } from "./images/nofi/billing/billing-subscription-panel-icon.svg";
import { ReactComponent as NofiBillingPaymentMethodPanel } from "./images/nofi/billing/billing-payment-method-panel-icon.svg";
import { ReactComponent as NofiBillingPaymentHistoryPanel } from "./images/nofi/billing/billing-payment-history-panel-icon.svg";

// nischa app-wide images
import NischaLogo from "./images/nischa/logo.png";
import NischaWordmark from "./images/nischa/wordmark.png";
import { ReactComponent as NischaCompositeWelcome } from "./images/nischa/welcome.svg";
import NischaFavicon from "./images/nischa/favicon.png";

// nischa budget images
import { ReactComponent as NischaBudgetDashboardAccountsPanel } from "./images/nischa/budget/dashboard-accounts-panel-icon.svg";
import { ReactComponent as NischaBudgetDashboardIntegrationPanel } from "./images/nischa/budget/dashboard-integration-panel-icon.svg";
import { ReactComponent as NischaBudgetDashboardPreferencesPanel } from "./images/nischa/budget/dashboard-preferences-panel-icon.svg";
import { ReactComponent as NischaBudgetOnboardingAddAccounts } from "./images/nischa/budget/onboarding-add-accounts.svg";
import { ReactComponent as NischaBudgetOnboardingBillingSuccess } from "./images/nischa/budget/onboarding-billing-success.svg";
import { ReactComponent as NischaBudgetOnboardingBilling } from "./images/nischa/budget/onboarding-billing.svg";
import { ReactComponent as NischaBudgetOnboardingDiscoveryInProgress } from "./images/nischa/budget/onboarding-discovery-in-progress.svg";
import { ReactComponent as NischaBudgetOnboardingFinished } from "./images/nischa/budget/onboarding-finished.svg";
import { ReactComponent as NischaBudgetOnboardingIntegrationSuccess } from "./images/nischa/budget/onboarding-integration-success.svg";
import { ReactComponent as NischaBudgetOnboardingIntegration } from "./images/nischa/budget/onboarding-integration.svg";
import { ReactComponent as NischaBudgetOnboardingPreferences } from "./images/nischa/budget/onboarding-preferences.svg";
import { ReactComponent as NischaBudgetOnboardingWelcome } from "./images/nischa/budget/onboarding-welcome.svg";

// nischa billing images
import { ReactComponent as NischaBillingSubscriptionPanel } from "./images/nischa/billing/billing-subscription-panel-icon.svg";
import { ReactComponent as NischaBillingPaymentMethodPanel } from "./images/nischa/billing/billing-payment-method-panel-icon.svg";
import { ReactComponent as NischaBillingPaymentHistoryPanel } from "./images/nischa/billing/billing-payment-history-panel-icon.svg";

export const ENVIRONMENT: Environment = process.env.NODE_ENV;
export const APP_ID: AppID = process.env.REACT_APP_APP_ID as AppID;

interface ClientCommonConfig {}

interface Auth0ClientConfig {
  domain: string;
  clientId: string;
  audience: string;
}
interface ClientEnvironmentConfig {
  id: "nofi" | "nischa";
  appLabel: string;
  appDescription: string;
  clientDomain: string;
  apiBaseUrl: string;
  auth0: Auth0ClientConfig;
  themeColor: string;
  favicon: string;
  images: {
    icon: string;
    wordmarkLogo: string;
    compositeWelcome: ReactSVGImageComponent;
    billing: BillingImages;
  };
  integrationConfig: {
    [T in IntegrationType]: ClientIntegrationConfig<T>;
  };
}

type ReactSVGImageComponent = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string;
  }
>;

interface BillingImages {
  subscriptionPanel: ReactSVGImageComponent;
  paymentMethodPanel: ReactSVGImageComponent;
  paymentHistoryPanel: ReactSVGImageComponent;
}
interface BudgetImages {
  dashboardAccountsPanel: ReactSVGImageComponent;
  dashboardIntegrationPanel: ReactSVGImageComponent;
  dashboardPreferencesPanel: ReactSVGImageComponent;
  onboardingWelcome: ReactSVGImageComponent;
  onboardingAddAccounts: ReactSVGImageComponent;
  onboardingBilling: ReactSVGImageComponent;
  onboardingBillingSuccess: ReactSVGImageComponent;
  onboardingIntegration: ReactSVGImageComponent;
  onboardingIntegrationSuccess: ReactSVGImageComponent;
  onboardingFinished: ReactSVGImageComponent;
  onboardingPreferences: ReactSVGImageComponent;
  onboardingDiscoveryInProgress: ReactSVGImageComponent;
}
interface StocksImages {
  dashboardDetectedStocksPanel: ReactSVGImageComponent;
  dashboardIntegrationPanel: ReactSVGImageComponent;
  onboardingWelcome: ReactSVGImageComponent;
  onboardingBilling: ReactSVGImageComponent;
  onboardingBillingSuccess: ReactSVGImageComponent;
  onboardingIntegration: ReactSVGImageComponent;
  onboardingIntegrationSuccess: ReactSVGImageComponent;
  onboardingFinished: ReactSVGImageComponent;
  onboardingDiscoveryInProgress: ReactSVGImageComponent;
}
interface ClientIntegrationConfig<T extends IntegrationType> {
  enabled: boolean;
  integrationLabel: string;
  notionClientId: string;
  notionOauthRoute: string;
  clientRoutePrefix: string;
  label: string;
  description: string;
  primaryColorPrefix: string;
  logo: string;
  wordmark: string;
  budgetImages: T extends "budget" ? BudgetImages : undefined;
  stocksImages: T extends "stocks" ? StocksImages : undefined;
  typeguardFilter: (
    integration: BaseIntegrationJSON
  ) => integration is IntegrationPayloadType<T>;
}

/**
 * Integration config that is common between all envs/apps.
 */
const INTEGRATION_COMMON_CONFIG = {
  budget: {
    enabled: true,
    integrationLabel: "NoFi Budget",
    clientRoutePrefix: "/budget",
    notionOauthRoute: "integration-init",
    label: "Budget",
    description: "Sync your financial accounts and transactions.",
    typeguardFilter: isBudgetIntegration,
    stocksImages: undefined,
    logo: NofiLogo,
    wordmark: NofiWordmark,
    budgetImages: {
      dashboardAccountsPanel: NofiBudgetDashboardAccountsPanel,
      dashboardIntegrationPanel: NofiBudgetDashboardIntegrationPanel,
      dashboardPreferencesPanel: NofiBudgetDashboardPreferencesPanel,
      onboardingWelcome: NofiBudgetOnboardingWelcome,
      onboardingAddAccounts: NofiBudgetOnboardingAddAccounts,
      onboardingBilling: NofiBudgetOnboardingBilling,
      onboardingBillingSuccess: NofiBudgetOnboardingBillingSuccess,
      onboardingIntegration: NofiBudgetOnboardingIntegration,
      onboardingIntegrationSuccess: NofiBudgetOnboardingIntegrationSuccess,
      onboardingFinished: NofiBudgetOnboardingFinished,
      onboardingPreferences: NofiBudgetOnboardingPreferences,
      onboardingDiscoveryInProgress: NofiBudgetOnboardingDiscoveryInProgress,
    },
  },
  stocks: {
    integrationLabel: "NoFi Stocks",
    clientRoutePrefix: "/stocks",
    notionOauthRoute: "stocks/integration-init",
    label: "Stocks",
    description: "Live updating stock tickers.",
    typeguardFilter: isStocksIntegration,
    budgetImages: undefined,
    logo: NofiStocksLogo,
    wordmark: NofiStocksWordmark,
    stocksImages: {
      dashboardDetectedStocksPanel: NofiStocksDashboardDetectedStocksPanel,
      dashboardIntegrationPanel: NofiStocksDashboardIntegrationPanel,
      onboardingWelcome: NofiStocksOnboardingWelcome,
      onboardingBilling: NofiBudgetOnboardingBilling,
      onboardingBillingSuccess: NofiBudgetOnboardingBillingSuccess,
      onboardingIntegration: NofiStocksOnboardingIntegration,
      onboardingIntegrationSuccess: NofiStocksOnboardingIntegrationSuccess,
      onboardingFinished: NofiStocksOnboardingFinished,
      onboardingDiscoveryInProgress: NofiStocksOnboardingDiscoveryInProgress,
    },
  },
} as const;

const developmentClientConfig: { [appId in AppID]: ClientEnvironmentConfig } = {
  nofi: {
    id: "nofi",
    appLabel: "NoFi",
    appDescription:
      "We build Notion apps that let you supercharge your workspace with real-time data and powerful templates. Select an app below to get started.",
    themeColor: "#31c48d",
    favicon: NofiFavicon,
    images: {
      icon: NofiLogo,
      wordmarkLogo: NofiWordmark,
      compositeWelcome: NofiCompositeWelcome,
      billing: {
        subscriptionPanel: NofiBillingSubscriptionPanel,
        paymentMethodPanel: NofiBillingPaymentMethodPanel,
        paymentHistoryPanel: NofiBillingPaymentHistoryPanel,
      },
    },
    auth0: {
      domain: "dev-gdzw0igmjoyrwuju.us.auth0.com",
      clientId: "msHChnibK3VqMxNlqh1chtLqwA4TgKw2",
      audience: "https://api.nofi.so",
    },
    clientDomain: "http://localhost:3000",
    apiBaseUrl: "http://localhost:3001",
    integrationConfig: {
      budget: {
        ...INTEGRATION_COMMON_CONFIG["budget"],
        notionClientId: "82c9880d-33ed-43e2-b258-9ef4f4019917",
        primaryColorPrefix: "nf-green",
      },
      stocks: {
        ...INTEGRATION_COMMON_CONFIG["stocks"],
        enabled: false,
        notionClientId: "068556b2-7cbb-4a40-94c6-8a89b8f930dc",
        primaryColorPrefix: "nf-purple",
      },
    },
  },
  nischa: {
    id: "nischa",
    appLabel: "Automated Personal Finance Assistant",
    appDescription:
      "We build Notion apps that let you supercharge your workspace with real-time data and powerful templates. Select an app below to get started.",
    themeColor: "#f4870f",
    favicon: NischaFavicon,
    images: {
      icon: NischaLogo,
      wordmarkLogo: NischaWordmark,
      compositeWelcome: NischaCompositeWelcome,
      billing: {
        subscriptionPanel: NischaBillingSubscriptionPanel,
        paymentMethodPanel: NischaBillingPaymentMethodPanel,
        paymentHistoryPanel: NischaBillingPaymentHistoryPanel,
      },
    },
    auth0: {
      domain: "dev-gdzw0igmjoyrwuju.us.auth0.com",
      clientId: "msHChnibK3VqMxNlqh1chtLqwA4TgKw2",
      audience: "https://api.nofi.so",
    },
    clientDomain: "http://localhost:3000",
    apiBaseUrl: "http://localhost:3001",
    integrationConfig: {
      budget: {
        ...INTEGRATION_COMMON_CONFIG["budget"],
        integrationLabel: "Automated Personal Finance Assistant",
        notionClientId: "8665db5a-0d66-40d4-aa32-1f664b267f1a",
        logo: NischaLogo,
        wordmark: NischaWordmark,
        budgetImages: {
          dashboardAccountsPanel: NischaBudgetDashboardAccountsPanel,
          dashboardIntegrationPanel: NischaBudgetDashboardIntegrationPanel,
          dashboardPreferencesPanel: NischaBudgetDashboardPreferencesPanel,
          onboardingWelcome: NischaBudgetOnboardingWelcome,
          onboardingAddAccounts: NischaBudgetOnboardingAddAccounts,
          onboardingBilling: NischaBudgetOnboardingBilling,
          onboardingBillingSuccess: NischaBudgetOnboardingBillingSuccess,
          onboardingIntegration: NischaBudgetOnboardingIntegration,
          onboardingIntegrationSuccess:
            NischaBudgetOnboardingIntegrationSuccess,
          onboardingFinished: NischaBudgetOnboardingFinished,
          onboardingPreferences: NischaBudgetOnboardingPreferences,
          onboardingDiscoveryInProgress:
            NischaBudgetOnboardingDiscoveryInProgress,
        },
        primaryColorPrefix: "nischa-orange",
      },
      stocks: {
        ...INTEGRATION_COMMON_CONFIG["stocks"],
        enabled: false,
        notionClientId: "068556b2-7cbb-4a40-94c6-8a89b8f930dc",
        primaryColorPrefix: "nf-purple",
      },
    },
  },
};

const productionClientConfig: { [appId in AppID]: ClientEnvironmentConfig } = {
  nofi: {
    id: "nofi",
    appLabel: "NoFi",
    appDescription:
      "We build Notion apps that let you supercharge your workspace with real-time data and powerful templates. Select an app below to get started.",
    themeColor: "#31c48d",
    favicon: NofiFavicon,
    images: {
      icon: NofiLogo,
      wordmarkLogo: NofiWordmark,
      compositeWelcome: NofiCompositeWelcome,
      billing: {
        subscriptionPanel: NofiBillingSubscriptionPanel,
        paymentMethodPanel: NofiBillingPaymentMethodPanel,
        paymentHistoryPanel: NofiBillingPaymentHistoryPanel,
      },
    },
    auth0: {
      domain: "nofi.us.auth0.com",
      clientId: "7RJkTZGcQxVqx4pFVsyqmUwhjuFQPff0",
      audience: "https://api.nofi.so",
    },
    clientDomain: "https://app.nofi.so",
    apiBaseUrl: "https://api.nofi.so",
    integrationConfig: {
      budget: {
        ...INTEGRATION_COMMON_CONFIG["budget"],
        notionClientId: "82c9880d-33ed-43e2-b258-9ef4f4019917",
        primaryColorPrefix: "nf-green",
      },
      stocks: {
        ...INTEGRATION_COMMON_CONFIG["stocks"],
        enabled: false,
        notionClientId: "068556b2-7cbb-4a40-94c6-8a89b8f930dc",
        primaryColorPrefix: "nf-purple",
      },
    },
  },
  nischa: {
    id: "nischa",
    appLabel: "Automated Personal Finance Assistant",
    appDescription:
      "We build Notion apps that let you supercharge your workspace with real-time data and powerful templates. Select an app below to get started.",
    themeColor: "#f4870f",
    favicon: NischaFavicon,
    images: {
      icon: NischaLogo,
      wordmarkLogo: NischaWordmark,
      compositeWelcome: NischaCompositeWelcome,
      billing: {
        subscriptionPanel: NischaBillingSubscriptionPanel,
        paymentMethodPanel: NischaBillingPaymentMethodPanel,
        paymentHistoryPanel: NischaBillingPaymentHistoryPanel,
      },
    },
    auth0: {
      domain: "automatedpersonalfinanceassistant.us.auth0.com",
      clientId: "UAu9o21QbCDl4K3KfgxNW3zWSgbjbdG4",
      audience: "https://api.automatedpersonalfinanceassistant.com",
    },
    clientDomain: "https://app.automatedpersonalfinanceassistant.com",
    apiBaseUrl: "https://api.automatedpersonalfinanceassistant.com",
    integrationConfig: {
      budget: {
        ...INTEGRATION_COMMON_CONFIG["budget"],
        integrationLabel: "Automated Personal Finance Assistant",
        notionClientId: "8665db5a-0d66-40d4-aa32-1f664b267f1a",
        logo: NischaLogo,
        wordmark: NischaWordmark,
        budgetImages: {
          dashboardAccountsPanel: NischaBudgetDashboardAccountsPanel,
          dashboardIntegrationPanel: NischaBudgetDashboardIntegrationPanel,
          dashboardPreferencesPanel: NischaBudgetDashboardPreferencesPanel,
          onboardingWelcome: NischaBudgetOnboardingWelcome,
          onboardingAddAccounts: NischaBudgetOnboardingAddAccounts,
          onboardingBilling: NischaBudgetOnboardingBilling,
          onboardingBillingSuccess: NischaBudgetOnboardingBillingSuccess,
          onboardingIntegration: NischaBudgetOnboardingIntegration,
          onboardingIntegrationSuccess:
            NischaBudgetOnboardingIntegrationSuccess,
          onboardingFinished: NischaBudgetOnboardingFinished,
          onboardingPreferences: NischaBudgetOnboardingPreferences,
          onboardingDiscoveryInProgress:
            NischaBudgetOnboardingDiscoveryInProgress,
        },
        primaryColorPrefix: "nischa-orange",
      },
      stocks: {
        ...INTEGRATION_COMMON_CONFIG["stocks"],
        enabled: false,
        notionClientId: "068556b2-7cbb-4a40-94c6-8a89b8f930dc",
        primaryColorPrefix: "nf-purple",
      },
    },
  },
};

const CLIENT_CONFIG_LOOKUP: {
  [env in Environment]: { [appId in AppID]: ClientEnvironmentConfig };
} = {
  production: productionClientConfig,
  development: developmentClientConfig,
  test: developmentClientConfig,
};

console.log(ENVIRONMENT, APP_ID);
export const CLIENT_CONFIG: ClientEnvironmentConfig & ClientCommonConfig =
  CLIENT_CONFIG_LOOKUP[ENVIRONMENT][APP_ID];

export const NOTION_BASE_OAUTH_URL: string =
  "https://api.notion.com/v1/oauth/authorize";

function isBudgetIntegration(
  integration: BaseIntegrationJSON
): integration is BudgetIntegrationJSON {
  return integration.type === "budget";
}

function isStocksIntegration(
  integration: BaseIntegrationJSON
): integration is StocksIntegrationJSON {
  return integration.type === "stocks";
}
