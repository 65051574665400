import { useContext, useEffect } from "react";
import { BannerContext } from "../providers/BannerProvider";
import { isUserSubscriptionValid } from "../utils";
import { UserInfoContext } from "../providers/UserInfoProvider";
import useAuth from "../hooks/useAuth";
import SubscriptionCard from "./SubscriptionCard";

const SUBSCRIPTION_BANNER_ID: string = "subscription_expired";

export default function BaseDashboard({
  children,
}: {
  children: React.ReactElement | Array<React.ReactElement>;
}) {
  const { isLoading } = useAuth();

  const {
    state: { currentUser, userSubscription, userSubscriptionLoading },
    createBillingSession,
  } = useContext(UserInfoContext);

  const {
    pushBanners,
    state: { banners },
  } = useContext(BannerContext);

  // Show banner if user subscription is in need of attention
  useEffect(() => {
    if (
      !currentUser || // user hasn't loaded yet
      userSubscriptionLoading || // userSubscription is loading
      !!banners.find((banner) => banner.id === SUBSCRIPTION_BANNER_ID) ||
      isUserSubscriptionValid(userSubscription) // user subscription is valid
    ) {
      return;
    }

    if (userSubscription === null) {
      return;
    }

    pushBanners([
      {
        type: "danger",
        text: (
          <span>
            Your subscription and/or payment info has expired. Please contact
            support@nofi.so to resubscribe.
          </span>
        ),
      },
    ]);
  }, [
    currentUser,
    userSubscription,
    pushBanners,
    createBillingSession,
    userSubscriptionLoading,
  ]);

  if (isLoading) {
    return <div className="animated loading m-2" style={{ height: 40 }}></div>;
  }

  return (
    <>
      {children}

      {userSubscription && (
        <SubscriptionCard userSubscription={userSubscription} />
      )}
    </>
  );
}
