import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import React, {
  useReducer,
  useCallback,
  useState,
  Reducer,
  useContext,
} from "react";
import useAuth from "../hooks/useAuth";
import { StockTickerJSON } from "../types";
import { createRequestConfig } from "../utils";
import { BannerContext } from "./BannerProvider";
import { ENVIRONMENT } from "../constants";

// const STOCKS_BASE_URL: string =
//   ENVIRONMENT === "production"
//     ? "https://api-stocks.nofi.so"
//     : "http://localhost:3004";

// const DEFAULT_REQUEST_CONFIG: Partial<AxiosRequestConfig> = {
//   baseURL: STOCKS_BASE_URL,
// };

export interface StockTickersState {
  tickers: Array<StockTickerJSON>;
  loading: boolean;
  lastScanJobId: string | null;
}

export interface StockTickersContextValue {
  state: StockTickersState;
  getItems: () => void;
  scanWorkspace: () => void;
}

type StockTickersAction =
  | { type: "GET_TICKERS"; tickers: Array<StockTickerJSON> }
  | { type: "SCAN_WORKSPACE"; jobId: string };

const noop = () => {};

export const StockTickersContext =
  React.createContext<StockTickersContextValue>({
    state: { tickers: [], loading: false, lastScanJobId: null },
    getItems: noop,
    scanWorkspace: noop,
  });

const Provider = ({ children }: { children: React.ReactNode }) => {
  const { getAccessTokenSilently } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);

  const { pushBanners } = useContext(BannerContext);

  const [state, dispatch] = useReducer<
    Reducer<StockTickersState, StockTickersAction>
  >(
    (state, action) => {
      const nextState: StockTickersState = {
        ...state,
        tickers: [...state.tickers],
        loading,
      };

      switch (action.type) {
        case "GET_TICKERS": {
          nextState.tickers = action.tickers;
          return nextState;
        }
        case "SCAN_WORKSPACE": {
          nextState.lastScanJobId = action.jobId;
          return nextState;
        }
      }
    },
    { tickers: [], loading, lastScanJobId: null }
  );

  const getItems = useCallback(async () => {
    setLoading(true);
    const accessToken = await getAccessTokenSilently();
    const res: AxiosResponse<any> = await axios.get(
      "/stocks/tickers",
      createRequestConfig(accessToken)
    );

    if (!res.data.success) {
      console.error("Encountered error while fetching plaid items for user.");
      console.error(res.data.message);
      console.error(res.data.error);
      return;
    }

    dispatch({ type: "GET_TICKERS", tickers: res.data.tickers });
    setLoading(false);
  }, [dispatch, getAccessTokenSilently]);

  const scanWorkspace = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    const res = await axios.post<any>(
      "/stocks/refresh-stock-tickers",
      undefined,
      createRequestConfig(accessToken)
    );

    if (!res.data.success) {
      console.error("Unable to refresh the plaid item.");
      return;
    }

    dispatch({
      type: "SCAN_WORKSPACE",
      jobId: res.data.jobId,
    });
  }, [dispatch, getAccessTokenSilently]);

  const value: StockTickersContextValue = {
    state: { ...state, loading },
    getItems,
    scanWorkspace,
  };

  return (
    <StockTickersContext.Provider value={value}>
      {children}
    </StockTickersContext.Provider>
  );
};

export default Provider;
