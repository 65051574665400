import BaseDashboard from "../components/BaseDashboard";
import DetectedStocksCard from "../components/DetectedStocksCard";
import IntegrationCard from "../components/IntegrationCard";
import { CLIENT_CONFIG } from "../constants";

export default function StocksDashboard() {
  return (
    <BaseDashboard>
      <IntegrationCard
        integrationType="stocks"
        icon={
          <CLIENT_CONFIG.integrationConfig.stocks.stocksImages.dashboardIntegrationPanel />
        }
      />
      <DetectedStocksCard />
    </BaseDashboard>
  );
}
