import axios, { AxiosResponse } from "axios";
import React, {
  useReducer,
  useCallback,
  useState,
  Reducer,
  useContext,
} from "react";
import useAuth from "../hooks/useAuth";
import {
  DeletePlaidItemResponse,
  GetPlaidItemsResponse,
  PlaidItemJSON,
  RefreshPlaidItemResponse,
} from "../types";
import { createRequestConfig } from "../utils";
import { BannerContext } from "./BannerProvider";

export interface PlaidItemsState {
  items: Array<PlaidItemJSON>;
  deletedItems: Array<PlaidItemJSON>;
  loading: boolean;
}

export interface PlaidItemsContextValue {
  state: PlaidItemsState;
  getItems: () => void;
  deleteItem: (itemId: string) => void;
  refreshItems: () => void;
  addItem: (item: PlaidItemJSON) => void;
}

type PlaidItemAction =
  | { type: "GET_ITEMS"; items: Array<PlaidItemJSON> }
  | { type: "DELETE_ITEM"; itemId: string }
  | { type: "RESTORE_ITEM"; itemId: string }
  | { type: "UPDATE_ITEM"; itemId: string; updates: Partial<PlaidItemJSON> }
  | { type: "REFRESH_ITEMS"; jobId: string }
  | { type: "ADD_ITEM"; item: PlaidItemJSON };

const noop = () => {};

export const PlaidItemContext = React.createContext<PlaidItemsContextValue>({
  state: { items: [], deletedItems: [], loading: false },
  getItems: noop,
  deleteItem: noop,
  refreshItems: noop,
  addItem: noop,
});

const Provider = ({ children }: { children: React.ReactNode }) => {
  const { getAccessTokenSilently } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);

  const { pushBanners } = useContext(BannerContext);

  const [state, dispatch] = useReducer<
    Reducer<PlaidItemsState, PlaidItemAction>
  >(
    (state, action) => {
      // do something

      const nextState: PlaidItemsState = {
        ...state,
        items: [...state.items],
        loading,
      };

      switch (action.type) {
        case "GET_ITEMS": {
          nextState.items = action.items;
          return nextState;
        }
        case "DELETE_ITEM": {
          const targetIndex: number = nextState.items.findIndex(
            (item) => item.itemId === action.itemId
          );
          if (targetIndex === -1) {
            return state;
          }

          const deletedItems = nextState.items.splice(targetIndex, 1);

          nextState.items = [...nextState.items];
          nextState.deletedItems = [...nextState.deletedItems, ...deletedItems];

          return nextState;
        }
        case "RESTORE_ITEM": {
          const itemToRestore: PlaidItemJSON | undefined =
            nextState.deletedItems.find(
              (item) => item.itemId === action.itemId
            );

          if (itemToRestore) {
            nextState.items.push(itemToRestore);
          }

          return nextState;
        }
        case "UPDATE_ITEM": {
          const targetIndex: number = nextState.items.findIndex(
            (item) => item.itemId === action.itemId
          );
          if (targetIndex === -1) {
            return state;
          }

          nextState.items[targetIndex] = {
            ...nextState.items[targetIndex],
            ...action.updates,
          };
          return nextState;
        }
        case "ADD_ITEM": {
          const targetIndex: number = nextState.items.findIndex(
            (item) => item.itemId === action.item.itemId
          );
          if (targetIndex === -1) {
            nextState.items = [...nextState.items, action.item];
          } else {
            nextState.items.splice(targetIndex, 1, action.item);
            nextState.items = [...nextState.items];
          }

          return nextState;
        }
        case "REFRESH_ITEMS": {
          nextState.items.forEach(
            (item) => (item.lastTransactionSyncJobId = action.jobId)
          );
          return nextState;
        }
      }
    },
    { items: [], deletedItems: [], loading }
  );

  const getItems = useCallback(async () => {
    setLoading(true);
    const accessToken = await getAccessTokenSilently();
    const res: AxiosResponse<GetPlaidItemsResponse> = await axios.get(
      "/budget/plaid-items",
      createRequestConfig(accessToken)
    );

    if (!res.data.success) {
      console.error("Encountered error while fetching plaid items for user.");
      console.error(res.data.message);
      console.error(res.data.error);
      return;
    }

    dispatch({ type: "GET_ITEMS", items: res.data.items });
    setLoading(false);
  }, [dispatch, getAccessTokenSilently]);

  const deleteItem = useCallback(
    async (itemId: string) => {
      // optimistic delete
      dispatch({ type: "DELETE_ITEM", itemId });
      // server-side delete
      const res = await axios.post<DeletePlaidItemResponse>(
        "/budget/delete-plaid-item",
        {
          itemId,
        },
        createRequestConfig(await getAccessTokenSilently())
      );

      if (!res.data.success) {
        pushBanners([
          {
            dismissible: true,
            type: "danger",
            text: "Unable to remove account at this time. Please try again later.",
          },
        ]);
        dispatch({ type: "RESTORE_ITEM", itemId });
        return;
      }

      pushBanners([
        {
          dismissible: true,
          type: "success",
          text: "Account was successfully removed.",
        },
      ]);
    },
    [dispatch, getAccessTokenSilently, pushBanners]
  );

  const refreshItems = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    const res = await axios.post<RefreshPlaidItemResponse>(
      "/budget/refresh-plaid-item",
      undefined,
      createRequestConfig(accessToken)
    );

    if (!res.data.success) {
      console.error("Unable to refresh the plaid item.");
      return;
    }

    dispatch({
      type: "REFRESH_ITEMS",
      jobId: res.data.transactionSyncJobId,
    });
  }, [dispatch, getAccessTokenSilently]);

  const addItem = useCallback(
    (item: PlaidItemJSON) => {
      dispatch({
        type: "ADD_ITEM",
        item: item,
      });
    },
    [dispatch]
  );

  const value: PlaidItemsContextValue = {
    state: { ...state, loading },
    getItems,
    deleteItem,
    refreshItems,
    addItem,
  };

  return (
    <PlaidItemContext.Provider value={value}>
      {children}
    </PlaidItemContext.Provider>
  );
};

export default Provider;
