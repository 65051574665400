import { useCallback, useContext, useEffect, useMemo } from "react";

import OnboardingStep, { PaginationItem } from "../components/OnboardingStep";
import usePlaid from "../hooks/usePlaid";
import { PlaidItemContext } from "../providers/PlaidItemsProvider";

import { useNavigate } from "react-router-dom";
import { getDashboardRoute, getOnboardingRoute } from "../utils";
import { IntegrationsContext } from "../providers/IntegrationsProvider";
import { CLIENT_CONFIG } from "../constants";

const PREV_PAGINATION_ITEM: PaginationItem = {
  label: "Preferences",
  path: getOnboardingRoute({ type: "budget", onboardingState: "preferences" }),
};

export default function OnboardingAddAccounts() {
  const { initialize } = usePlaid();
  const navigate = useNavigate();

  const {
    state: { items },
  } = useContext(PlaidItemContext);
  const { currentIntegration, updateOnboardingState } =
    useContext(IntegrationsContext);

  useEffect(() => {
    const integration = currentIntegration("budget", {
      discoveryComplete: true,
    });
    if (!integration || integration.onboardingState === "accounts") {
      return;
    }
    updateOnboardingState(integration.id, "accounts");
  }, [currentIntegration]);

  useEffect(() => {
    if (items.length) {
      navigate(
        getOnboardingRoute({ type: "budget", onboardingState: "complete" })
      );
    }
  }, [items]);

  const handleClick = useCallback(() => {
    initialize();
  }, [initialize]);

  const NEXT_PAGINATION_ITEM = useMemo<PaginationItem>(
    () => ({
      label: "Go To Dashboard",
      path: getDashboardRoute({ type: "budget" }),
      onClick: () => {
        const integration = currentIntegration("budget", {
          discoveryComplete: true,
        });

        if (!integration) {
          return;
        }

        updateOnboardingState(integration.id, "complete");
      },
    }),
    [updateOnboardingState, currentIntegration]
  );

  return (
    <OnboardingStep
      title="Link your first account"
      description="Link your first account to start importing transaction and account data into your Notion workspace. You can also link accounts from your dashboard."
      prev={PREV_PAGINATION_ITEM}
      next={NEXT_PAGINATION_ITEM}
      hero={
        <CLIENT_CONFIG.integrationConfig.budget.budgetImages.onboardingAddAccounts />
      }
    >
      <button className="btn-primary mt-2" onClick={handleClick}>
        Add Account
      </button>
    </OnboardingStep>
  );
}
