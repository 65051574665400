import { useCallback, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import PreferencesCard from "../components/PreferencesCard";
import IntegrationCard from "../components/IntegrationCard";
import AccountsCard from "../components/AccountsCard";
import { PlaidItemContext } from "../providers/PlaidItemsProvider";
import { BatchJobContext } from "../providers/BatchJobsProvider";
import BaseDashboard from "../components/BaseDashboard";
import { CLIENT_CONFIG } from "../constants";

export default function Dashboard() {
  const navigate = useNavigate();

  const {
    state: { items },
  } = useContext(PlaidItemContext);

  const { getJobs } = useContext(BatchJobContext);

  // Fetch batch jobs when items change
  useEffect(() => {
    getJobs(
      items
        .map((item) => item.lastTransactionSyncJobId)
        .filter((item): item is string => !!item)
    );
  }, [items]);

  const handleLinkAccountClick = useCallback(() => {
    navigate("/plaid-link");
  }, [navigate]);

  const handleRefreshClick = useCallback(() => {
    console.log("Click");
  }, []);

  return (
    <BaseDashboard>
      <IntegrationCard
        integrationType="budget"
        icon={
          <CLIENT_CONFIG.integrationConfig.budget.budgetImages.dashboardIntegrationPanel />
        }
      />
      <AccountsCard
        integrationType="budget"
        onAddAccountClick={handleLinkAccountClick}
        onRefreshClick={handleRefreshClick}
      />
      <PreferencesCard />
    </BaseDashboard>
  );
}
