import classnames from "classnames";
import { Link } from "react-router-dom";
export interface PaginationItem {
  label: string;
  path: string;
  disabled?: boolean;
  /**
   * Additional side effects to perform before navigating.
   */
  onClick?: () => void;
}

interface OnboardingStepProps {
  title: string;
  hero: React.ReactNode;
  description?: string;
  children?: React.ReactNode;
  prev: PaginationItem | null;
  next: PaginationItem | null;
  maxWidth?: number;
}
export default function OnboardingStep({
  title,
  hero,
  description,
  children,
  prev,
  next,
  maxWidth = 500,
}: OnboardingStepProps) {
  return (
    <div
      key="0"
      style={{ maxWidth }}
      className="card w-90p u-flex u-flex-column u-justify-center animated fadeIn px-2"
    >
      <div className="content u-text-center pt-1 px-1 pt-3-sm px-2-sm">
        <div
          style={{ width: "20rem", margin: "auto" }}
          className="max-w-90p u-text-center"
        >
          {!!hero && hero}
        </div>
        <h5
          className="title mt-4 mb-0"
          style={{ maxWidth: "27rem", margin: "auto" }}
        >
          {title}
        </h5>
        {!!description && (
          <p className="mt-2" style={{ maxWidth: "28rem", margin: "auto" }}>
            {description}
          </p>
        )}
        {!!children && children}
        <div className="pagination no-bullets">
          {!!prev && (
            <div className="pagination-item pagination-prev">
              <Link
                onClick={prev.onClick}
                to={prev.path}
                className={classnames("u-block", prev.disabled && "disabled")}
              >
                <p className="pagination-item-subtitle u-inline-flex u-items-center">
                  <i className="ri-arrow-left-line" /> Previous
                </p>
                <p className="m-0 text-lg">{prev.label}</p>
              </Link>
            </div>
          )}
          {!!next && (
            <div className="pagination-item pagination-next">
              <Link
                onClick={next.onClick}
                to={next.path}
                className={classnames("u-block", next.disabled && "disabled")}
              >
                <p className="pagination-item-subtitle u-inline-flex u-items-center">
                  Next <i className="ri-arrow-right-line" />
                </p>
                <p className="m-0 text-lg">{next.label}</p>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
