import { useCallback, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import usePlaid from "../hooks/usePlaid";
import { BannerContext } from "../providers/BannerProvider";
import { UserInfoContext } from "../providers/UserInfoProvider";

export default function PlaidOAuth() {
  const {
    state: { currentUser },
  } = useContext(UserInfoContext);
  const { pushBanners } = useContext(BannerContext);
  const navigate = useNavigate();

  const handleSuccess = useCallback(() => {
    console.log("oAuth Success!");
    navigate("/");
  }, [navigate]);

  const handleExit = useCallback(() => {
    console.log("oAuth Exited!");
    navigate("/");
  }, [navigate]);

  const { ready, open } = usePlaid({
    redirected: true,
    persistedLinkToken: currentUser?.plaidLinkToken || null,
    onSuccess: handleSuccess,
    onExit: handleExit,
  });

  const linkOpened = useRef<boolean>(false);
  useEffect(() => {
    if (ready && !linkOpened.current) {
      linkOpened.current = true;
      open();
    }

    if (currentUser?.plaidLinkToken === null) {
      pushBanners([
        {
          type: "danger",
          text: "Unable to complete oAuth redirect. Please revisit the original browser/window to continue.",
        },
      ]);
      return;
    }
  });

  return <></>;
}
