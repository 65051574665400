import { useContext, useMemo } from "react";
import { PlaidItemContext } from "../providers/PlaidItemsProvider";
import PlaidItemRow from "./PlaidItemRow";
import StepCard from "./DashboardCard";
import { isJobPolling } from "../utils";
import { BatchJobContext } from "../providers/BatchJobsProvider";
import { IntegrationType } from "../types";
import { CLIENT_CONFIG } from "../constants";

interface ComponentProps {
  onAddAccountClick: () => void;
  onRefreshClick: () => void;
  integrationType: IntegrationType;
}

export default function Step4({
  onAddAccountClick,
  integrationType,
}: ComponentProps) {
  const {
    state: { items, loading: itemsLoading },
    deleteItem,
    refreshItems,
  } = useContext(PlaidItemContext);
  const {
    state: { loading: jobsLoading, pollingLookup },
  } = useContext(BatchJobContext);

  const allJobsPolling = useMemo<boolean>(() => {
    return items.every((item) =>
      isJobPolling(item.lastTransactionSyncJobId, pollingLookup)
    );
  }, [items, pollingLookup]);

  const refreshDisabled = useMemo<boolean>(() => {
    return itemsLoading || jobsLoading || !items.length || allJobsPolling;
  }, [itemsLoading, jobsLoading, items, allJobsPolling]);

  return (
    <StepCard
      integrationType={integrationType}
      image={
        <CLIENT_CONFIG.integrationConfig.budget.budgetImages.dashboardAccountsPanel />
      }
      title="Linked Accounts"
      subtitle="Add your bank, credit card, loans, and any other financial institution you'd like to keep track of in your Notion workspace."
      buttonContent={
        <>
          <i className="ri-add-fill mr-1 text-md"></i>Add
        </>
      }
      secondaryButtonContent={
        <>
          <i className="ri-rotate-lock-line mr-1 text-md"></i>Refresh
        </>
      }
      secondaryButtonDisabled={refreshDisabled}
      secondaryButtonTooltip={
        refreshDisabled ? "Update in progress" : "Refresh account data"
      }
      onSecondaryButtonClick={() => refreshItems()}
      onButtonClick={onAddAccountClick}
    >
      <>
        {itemsLoading && !items.length ? (
          <div className="animated loading m-2" style={{ height: 40 }}></div>
        ) : (
          items.map((item, index) => (
            <PlaidItemRow
              key={item.itemId}
              item={item}
              deleteItem={deleteItem}
              hideDivider={index === items.length - 1}
            />
          ))
        )}
      </>
    </StepCard>
  );
}
