import ReactGA from "react-ga4";
import { useCallback } from "react";

export type EventName = "initiateInstall" | "initiateCheckout";

export default function useEventTracking() {
  const trackEvent = useCallback(
    (
      eventName: EventName,
      params?: Record<string, string | number | boolean>
    ) => {
      fbq("trackCustom", eventName, params);
      ReactGA.event(eventName, params);
    },
    [fbq]
  );

  return {
    trackEvent,
  };
}
