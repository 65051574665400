import { useState, useCallback, useEffect, useContext } from "react";
import axios, { AxiosResponse } from "axios";
import { createRequestConfig } from "../utils";

import {
  usePlaidLink,
  PlaidLinkOptions,
  PlaidLinkOnSuccess,
  PlaidLinkOnEvent,
  PlaidLinkOnExit,
  PlaidLinkOnSuccessMetadata,
} from "react-plaid-link";
import { PlaidItemContext } from "../providers/PlaidItemsProvider";
import { ExchangePublicTokenResponse } from "../types";
import useAuth from "./useAuth";
import { UserInfoContext } from "../providers/UserInfoProvider";

type Errable<T extends { success: boolean }> = ErrorResponse | T;

type InitLinkResonse = Errable<{
  linkToken: string;
  expiration: number;
  success: true;
}>;

interface ErrorResponse {
  success: false;
  error: unknown;
  message: string;
}

export interface UsePlaidOptions {
  redirected?: boolean;
  persistedLinkToken?: string | null;
  onSuccess?: () => void;
  onExit?: () => void;
}

export default function usePlaid({
  redirected,
  persistedLinkToken,
  onSuccess,
  onExit,
}: UsePlaidOptions = {}) {
  const [linkToken, setLinkToken] = useState<string | null>(null);

  const { getAccessTokenSilently, user } = useAuth();
  const { addItem } = useContext(PlaidItemContext);
  const { updateUserInfo } = useContext(UserInfoContext);

  const handleSuccess = useCallback<PlaidLinkOnSuccess>(
    async (publicToken, metadata: PlaidLinkOnSuccessMetadata) => {
      const accessToken = await getAccessTokenSilently();
      try {
        const res = await axios.post<ExchangePublicTokenResponse>(
          "/budget/exchange-public-token",
          {
            publicToken,
            institutionId: metadata.institution?.institution_id || null,
            institutionName: metadata.institution?.name || null,
            accounts: metadata.accounts,
          },
          createRequestConfig(accessToken)
        );

        if (!res.data.success) {
          console.log("Failed to exchange token");
          return;
        }

        addItem(res.data.item);
        !!onSuccess && onSuccess();
      } catch (e) {
        console.error(e);
      } finally {
        setLinkToken(null);
        document.body.style.overflow = "initial";
        await updateUserInfo({ plaidLinkToken: null });
      }
    },
    [updateUserInfo]
  );

  const handleEvent = useCallback<PlaidLinkOnEvent>((eventName, metadata) => {},
  []);

  const handleExit = useCallback<PlaidLinkOnExit>(
    async (error, metadata) => {
      // HACK: idk plaid is dumb and trying to do this immediately doesn't work b/c this executes in the iFrame
      setTimeout(() => {
        document.body.style.overflow = "initial";
      }, 1000);
      setLinkToken(null);
      await updateUserInfo({ plaidLinkToken: null });
      if (onExit) {
        onExit();
      }
    },
    [updateUserInfo, onExit]
  );

  const config: PlaidLinkOptions = {
    onSuccess: handleSuccess,
    onExit: handleExit,
    onEvent: handleEvent,
    token: persistedLinkToken || linkToken,
    receivedRedirectUri: redirected ? window.location.href : undefined,
  };

  const { open, exit, ready, error } = usePlaidLink(config);

  useEffect(() => {
    if (!ready) {
      return;
    }

    open();
  }, [ready]);

  const initialize = useCallback(
    async ({
      plaidAccessToken,
      accountSelection,
    }: {
      plaidAccessToken?: string | null;
      accountSelection?: boolean;
    } = {}) => {
      if (!user) {
        console.error("Cannot initialize plaid without an authenticated user.");
        return;
      }

      if (ready) {
        open();
        return;
      }

      const accessToken = await getAccessTokenSilently();
      const res = await axios.get<any, AxiosResponse<InitLinkResonse>>(
        "/budget/init-link",
        createRequestConfig(
          accessToken,
          plaidAccessToken
            ? { params: { accessToken: plaidAccessToken, accountSelection } }
            : {}
        )
      );

      if (!res.data.success) {
        console.log(res.data.message);
        return;
      }

      setLinkToken(res.data.linkToken);
      await updateUserInfo({ plaidLinkToken: res.data.linkToken });
    },
    [ready, open, user, updateUserInfo]
  );

  return { open, exit, ready, initialize };
}
