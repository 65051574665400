import axios, { AxiosResponse } from "axios";
import React, {
  useReducer,
  useCallback,
  Reducer,
  useContext,
  useState,
} from "react";
import useAuth from "../hooks/useAuth";
import {
  Banner,
  GetUserInfoResponse,
  IntegrationType,
  NofiAPIResponse,
  StripeSubscriptionJSON,
  SubmitEarlyAccessCodeResponse,
  UserSubscriptionResponse,
  UserJSON,
  GetUserSubscriptionResponse,
} from "../types";
import { createRequestConfig } from "../utils";
import { BannerContext } from "./BannerProvider";
import { CLIENT_CONFIG } from "../constants";

export interface UserInfoState {
  userInfoLoading: boolean;
  userSubscriptionLoading: boolean;
  currentUser: UserJSON | null;
  userSubscription: StripeSubscriptionJSON | null;
}

export interface UserInfoContextValue {
  state: UserInfoState;
  getUserInfo: () => void;
  getUserSubscription: () => void;
  updateUserInfo: (updates: Partial<UserJSON>) => void;
  subscribeToIntegration: (integrationType: IntegrationType) => void;
  unsubscribeFromIntegration: (integrationType: IntegrationType) => void;
  createCheckoutSession: (integrationType: IntegrationType) => void;
  createBillingSession: () => void;
  submitEarlyAccessCode: (code: string) => Promise<boolean>;
}

type UserInfoAction =
  | { type: "UPDATE_USER_INFO"; userInfo: UserJSON }
  | {
      type: "UPDATE_USER_SUBSCRIPTION";
      userSubscription: StripeSubscriptionJSON | null;
    };

const noop = () => {};

export const UserInfoContext = React.createContext<UserInfoContextValue>({
  state: {
    currentUser: null,
    userSubscription: null,
    userInfoLoading: false,
    userSubscriptionLoading: false,
  },
  getUserInfo: noop,
  updateUserInfo: noop,
  createCheckoutSession: noop,
  createBillingSession: noop,
  submitEarlyAccessCode: async () => false,
  subscribeToIntegration: noop,
  unsubscribeFromIntegration: noop,
  getUserSubscription: noop,
});

const Provider = ({ children }: { children: React.ReactNode }) => {
  const { pushBanners } = useContext(BannerContext);
  const { getAccessTokenSilently } = useAuth();
  const [userInfoLoading, setUserInfoLoading] = useState<boolean>(false);
  const [userSubscriptionLoading, setUserSubscriptionLoading] =
    useState<boolean>(false);

  const [state, dispatch] = useReducer<Reducer<UserInfoState, UserInfoAction>>(
    (state, action) => {
      const nextState: UserInfoState = {
        ...state,
      };

      switch (action.type) {
        case "UPDATE_USER_INFO": {
          nextState.currentUser = action.userInfo;
          return nextState;
        }
        case "UPDATE_USER_SUBSCRIPTION": {
          nextState.userSubscription = action.userSubscription;
          return nextState;
        }
      }
    },
    {
      currentUser: null,
      userSubscription: null,
      userInfoLoading: false,
      userSubscriptionLoading: false,
    }
  );

  const getUserInfo = useCallback(async () => {
    setUserInfoLoading(true);
    const accessToken = await getAccessTokenSilently();
    const res: AxiosResponse<GetUserInfoResponse> = await axios.get(
      "/user-info",
      createRequestConfig(accessToken)
    );

    if (!res.data.success) {
      console.error("Encountered error while fetching user info.");
      console.error(res.data.message);
      console.error(res.data.error);
      return;
    }

    dispatch({ type: "UPDATE_USER_INFO", userInfo: res.data.userInfo });
    setUserInfoLoading(false);
  }, [dispatch, getAccessTokenSilently]);

  const updateUserInfo = useCallback(
    async (updates: Partial<UserJSON>) => {
      if (state.currentUser === null) {
        console.log("Cannot update user info for null user");
        return;
      }

      const accessToken = await getAccessTokenSilently();
      const nextUserInfo: UserJSON = { ...state.currentUser };

      if (typeof updates.transactionStartDate !== "undefined") {
        nextUserInfo.transactionStartDate = updates.transactionStartDate;
      }

      if (typeof updates.plaidLinkToken !== "undefined") {
        nextUserInfo.plaidLinkToken = updates.plaidLinkToken;
      }

      // set optimistic value locally
      dispatch({ type: "UPDATE_USER_INFO", userInfo: nextUserInfo });
      const res = await axios.post<NofiAPIResponse<{}>>(
        "/update-user-info",
        nextUserInfo,
        createRequestConfig(accessToken)
      );

      if (!res.data.success) {
        // restore cached previous value.
        dispatch({ type: "UPDATE_USER_INFO", userInfo: state.currentUser });
      }
    },
    [state.currentUser, getAccessTokenSilently, dispatch]
  );

  const createCheckoutSession = useCallback(
    async (integrationType: IntegrationType) => {
      const accessToken = await getAccessTokenSilently();
      const res = await axios.post<NofiAPIResponse<{ sessionUrl: string }>>(
        "/create-checkout-session",
        { integrationType },
        createRequestConfig(accessToken)
      );

      if (!res.data.success) {
        console.error("Failed to fetch checkout session.");
        return;
      }

      window.location.href = res.data.sessionUrl;
    },
    [getAccessTokenSilently]
  );

  const createBillingSession = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    const res = await axios.post<NofiAPIResponse<{ sessionUrl: string }>>(
      "/create-billing-session",
      {},
      createRequestConfig(accessToken)
    );
    if (!res.data.success) {
      console.error("Failed to fetch billing session.");
      return;
    }

    window.location.href = res.data.sessionUrl;
  }, [getAccessTokenSilently]);

  const submitEarlyAccessCode = useCallback(
    async (code: string): Promise<boolean> => {
      const accessToken = await getAccessTokenSilently();
      try {
        const res = await axios.post<SubmitEarlyAccessCodeResponse>(
          "/early-access",
          { code },
          createRequestConfig(accessToken)
        );

        if (!res.data.success) {
          pushBanners([
            {
              text: "Something went wrong. Please try again later.",
              type: "danger",
              dismissible: true,
            },
          ]);
          return false;
        }

        dispatch({
          type: "UPDATE_USER_SUBSCRIPTION",
          userSubscription: res.data.userSubscription,
        });
      } catch (e: any) {
        console.log(e);
        if (e.response.status === 400) {
          pushBanners([
            {
              text: "Invalid code. Please double check your early access code and try again.",
              type: "danger",
              dismissible: true,
            },
          ]);
          return false;
        } else {
          pushBanners([
            {
              text: "Something went wrong. Please try again later.",
              type: "danger",
              dismissible: true,
            },
          ]);
          return false;
        }
      }

      return true;
    },
    [getAccessTokenSilently, pushBanners, dispatch]
  );

  const subscribeToIntegration = useCallback(
    async (integrationType: IntegrationType) => {
      const failureBanner: Omit<Banner, "id"> = {
        text: `Unable to add ${CLIENT_CONFIG.integrationConfig[integrationType].label} to your subscription at this time. Please try again later or contact support@nofi.so.`,
        type: "danger",
        dismissible: true,
      };
      const accessToken = await getAccessTokenSilently();
      try {
        const res = await axios.post<UserSubscriptionResponse>(
          "/update-user-subscription",
          { integrationsToAdd: [integrationType] },
          createRequestConfig(accessToken)
        );

        if (!res.data.success) {
          pushBanners([failureBanner]);
          return;
        }

        dispatch({
          type: "UPDATE_USER_SUBSCRIPTION",
          userSubscription: res.data.userSubscription,
        });
      } catch (e: any) {
        console.log(e);
        pushBanners([failureBanner]);
      }
    },
    [dispatch, pushBanners]
  );

  const unsubscribeFromIntegration = useCallback(
    async (integrationType: IntegrationType) => {
      const failureBanner: Omit<Banner, "id"> = {
        text: `Unable to remove ${CLIENT_CONFIG.integrationConfig[integrationType].label} from your subscription at this time. Please try again later or contact support@nofi.so.`,
        type: "danger",
        dismissible: true,
      };

      const accessToken = await getAccessTokenSilently();
      try {
        const res = await axios.post<UserSubscriptionResponse>(
          "/update-user-subscription",
          { integrationsToRemove: [integrationType] },
          createRequestConfig(accessToken)
        );

        if (!res.data.success) {
          pushBanners([failureBanner]);
          return;
        }

        dispatch({
          type: "UPDATE_USER_SUBSCRIPTION",
          userSubscription: res.data.userSubscription,
        });
        pushBanners([
          {
            text: `Successfully removed ${CLIENT_CONFIG.integrationConfig[integrationType].label}`,
            type: "success",
            dismissible: true,
          },
        ]);
      } catch (e: any) {
        console.log(e);
        pushBanners([failureBanner]);
      }
    },
    [dispatch, pushBanners]
  );
  const getUserSubscription = useCallback(async () => {
    setUserSubscriptionLoading(true);
    const accessToken = await getAccessTokenSilently();
    const res = await axios.get<GetUserSubscriptionResponse>(
      "/user-subscription",
      createRequestConfig(accessToken)
    );

    if (res.data.success) {
      dispatch({
        type: "UPDATE_USER_SUBSCRIPTION",
        userSubscription: res.data.userSubscription,
      });
    } else {
      console.error("Failed to get user subscription");
    }

    setUserSubscriptionLoading(false);
  }, [dispatch]);

  const value: UserInfoContextValue = {
    state: { ...state, userInfoLoading, userSubscriptionLoading },
    getUserInfo,
    updateUserInfo,
    createCheckoutSession,
    createBillingSession,
    submitEarlyAccessCode,
    subscribeToIntegration,
    unsubscribeFromIntegration,
    getUserSubscription,
  };

  return (
    <UserInfoContext.Provider value={value}>
      {children}
    </UserInfoContext.Provider>
  );
};

export default Provider;
