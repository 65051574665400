import React, { useContext, useEffect, useRef } from "react";
import useAuth from "../hooks/useAuth";
import { IntegrationsContext } from "../providers/IntegrationsProvider";
import { PlaidItemContext } from "../providers/PlaidItemsProvider";
import { UserInfoContext } from "../providers/UserInfoProvider";

export default function DataFetchContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  const { isLoading, isAuthenticated } = useAuth();
  const initialFetch = useRef<boolean>(false);

  const { getUserInfo, getUserSubscription } = useContext(UserInfoContext);
  const { getIntegrations } = useContext(IntegrationsContext);
  const { getItems } = useContext(PlaidItemContext);

  useEffect(() => {
    if (initialFetch.current || isLoading || !isAuthenticated) {
      return;
    }

    initialFetch.current = true;
    getUserInfo();
    getUserSubscription();
    getIntegrations(true);
    getItems();
  });

  return <>{children}</>;
}
