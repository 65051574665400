import { useCallback, useContext } from "react";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import StepCard from "./DashboardCard";
import { UserInfoContext } from "../providers/UserInfoProvider";
import { CLIENT_CONFIG } from "../constants";

const MAX_DATE: string = new Date().toISOString().split("T")[0];

export default function PreferencesCard() {
  const {
    state: { currentUser },
    updateUserInfo,
  } = useContext(UserInfoContext);

  const handleStartDateChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateUserInfo({
        transactionStartDate: e.target.value
          ? utcToZonedTime(
              e.target.value,
              Intl.DateTimeFormat().resolvedOptions().timeZone
            ).valueOf()
          : null,
      });
    },
    [updateUserInfo]
  );

  return (
    <StepCard
      integrationType="budget"
      image={
        <CLIENT_CONFIG.integrationConfig.budget.budgetImages.dashboardPreferencesPanel />
      }
      title="Preferences"
      subtitle="Tweak the below settings to your liking (or don't, the defaults will work just fine)."
    >
      <div className="row u-items-center w-100p" style={{ maxWidth: 640 }}>
        <div className="col-xs-6 px-0 px-1-sm">
          <p className="mt-2 mb-1 text-md font-semibold">
            Earliest Transaction Date:
          </p>
          <p className="m-0 text-sm">
            We'll ignore transactions before this date. By default we pull 24
            months of historical data.
          </p>
        </div>
        <div className="col-xs-6  px-0 px-1-sm">
          <input
            type="date"
            max={MAX_DATE}
            value={
              currentUser?.transactionStartDate
                ? format(
                    new Date(currentUser.transactionStartDate),
                    "yyyy-MM-dd"
                  )
                : undefined
            }
            onChange={handleStartDateChange}
          />
        </div>
      </div>
    </StepCard>
  );
}
