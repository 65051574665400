import { useContext, useEffect, useMemo } from "react";
import OnboardingStep from "../components/OnboardingStep";
import { IntegrationsContext } from "../providers/IntegrationsProvider";
import { IntegrationType } from "../types";
import { getDashboardRoute } from "../utils";
import { CLIENT_CONFIG } from "../constants";

export default function OnboardingFinished({
  integrationType,
  hero,
}: {
  integrationType: IntegrationType;
  hero: React.ReactNode;
}) {
  const { currentIntegration, updateOnboardingState } =
    useContext(IntegrationsContext);

  useEffect(() => {
    const integration = currentIntegration(integrationType, {
      discoveryComplete: true,
    });
    if (!integration || integration.onboardingState === "complete") {
      return;
    }

    updateOnboardingState(integration.id, "complete");
  }, [currentIntegration, integrationType]);

  const next = useMemo(
    () => ({
      label: "Go to dashboard",
      path: getDashboardRoute({ type: integrationType }),
    }),
    [integrationType]
  );

  return (
    <OnboardingStep
      title="You're all set!"
      description={`Everything is up and running. Go checkout your dashboard for the latest status of your new ${CLIENT_CONFIG.integrationConfig[
        integrationType
      ].integrationLabel.toLowerCase()} integration.`}
      prev={null}
      next={next}
      hero={hero}
    ></OnboardingStep>
  );
}
