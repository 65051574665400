import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import classnames from "classnames";
import BaseNav from "./BaseNav";
import { UserInfoContext } from "../providers/UserInfoProvider";
import useAuth from "../hooks/useAuth";
import { getDashboardRoute, getOnboardingRoute } from "../utils";
import {
  IntegrationsContext,
  IntegrationStatus,
} from "../providers/IntegrationsProvider";
import { BaseIntegrationJSON, IntegrationType } from "../types";
import { useParams } from "react-router-dom";
import { CLIENT_CONFIG } from "../constants";

type NavItem = "budget" | "stocks";

function isIntegrationActive(integrationType: IntegrationType): boolean {
  return (
    window.location.href.includes(
      getDashboardRoute({ type: integrationType })
    ) ||
    window.location.href.includes(
      getOnboardingRoute({ type: integrationType, onboardingState: "welcome" })
        .split("/")
        .slice(0, -1)
        .join("/")
    )
  );
}

export default function AuthNav({
  disableNavigation,
}: {
  disableNavigation?: boolean;
}) {
  const [menuActive, setMenuActive] = useState(false);
  const [activeNavItem, setActiveNavItem] = useState<NavItem | null>(null);
  const { user, logout } = useAuth();
  const handleLogout = useCallback(() => {
    logout();
  }, [logout]);

  const {
    state: {
      integrations,
      loading: integrationsLoading,
      stocksIntegrationStatus,
      budgetIntegrationStatus,
    },
  } = useContext(IntegrationsContext);

  const { createBillingSession } = useContext(UserInfoContext);

  const params = useParams();

  useEffect(() => {
    if (isIntegrationActive("budget")) {
      setActiveNavItem("budget");
    }
    if (isIntegrationActive("stocks")) {
      setActiveNavItem("stocks");
    }
  }, []);

  const handleBilling = useCallback(() => {
    createBillingSession();
  }, [createBillingSession]);

  const handleMenuClick = useCallback(() => {
    setMenuActive((prev) => !prev);
  }, []);

  const avatarTextRaw: string =
    user?.given_name || user?.nickname || user?.name || "User";

  return (
    <BaseNav
      onMenuClick={handleMenuClick}
      logo={
        params.integrationType &&
        CLIENT_CONFIG.integrationConfig[
          params.integrationType as "budget" | "stocks"
        ].enabled
          ? CLIENT_CONFIG.integrationConfig[
              params.integrationType as IntegrationType
            ].wordmark
          : undefined
      }
    >
      <div
        className={classnames("header-nav", menuActive && "active")}
        id="header-menu"
      >
        <div className="nav-left ">
          <div className="nav-item text-center no-hover pr-2">
            <span className="text-sm">{`Welcome, ${avatarTextRaw}`}</span>
          </div>
          {!disableNavigation && (
            <>
              {CLIENT_CONFIG.integrationConfig.budget.enabled && (
                <div
                  className={classnames(
                    "nav-item",
                    activeNavItem === "budget" && "active",
                    integrationsLoading && "btn--disabled"
                  )}
                >
                  <a
                    href={getNavHrefForIntegration(
                      "budget",
                      budgetIntegrationStatus,
                      integrations
                    )}
                  >
                    Budget
                  </a>
                </div>
              )}
              {CLIENT_CONFIG.integrationConfig.stocks.enabled && (
                <div
                  className={classnames(
                    "nav-item",
                    activeNavItem === "stocks" && "active"
                  )}
                >
                  <a
                    href={getNavHrefForIntegration(
                      "stocks",
                      stocksIntegrationStatus,
                      integrations
                    )}
                  >
                    Stocks
                  </a>
                </div>
              )}
              <div
                className={classnames(
                  "nav-item",
                  window.location.href.split("/").includes("billing") &&
                    "active"
                )}
              >
                <a onClick={handleBilling}>Billing</a>
              </div>
            </>
          )}
        </div>
        <div className="nav-right">
          <div className="nav-item">
            <a onClick={handleLogout}>Logout</a>
          </div>
        </div>
      </div>
    </BaseNav>
  );
}

function getNavHrefForIntegration(
  integrationType: IntegrationType,
  integrationStatus: IntegrationStatus,
  integrations: Array<BaseIntegrationJSON>
): string {
  switch (integrationStatus) {
    case "non-existent": {
      return getOnboardingRoute({
        type: integrationType,
        onboardingState: "welcome",
      });
    }
    case "incomplete": {
      const targetIntegration = integrations.find(
        (integration) => integration.type === integrationType
      );
      return getOnboardingRoute({
        type: integrationType,
        onboardingState: targetIntegration?.onboardingState || "welcome",
      });
    }
    case "completed": {
      return getDashboardRoute({ type: integrationType });
    }
  }
}
