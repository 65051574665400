import { useMemo } from "react";
import OnboardingStep, { PaginationItem } from "../components/OnboardingStep";
import { IntegrationType } from "../types";
import { getOnboardingRoute } from "../utils";

export default function OnboardingIntro({
  title,
  description,
  integrationType,
  hero,
}: {
  title: string;
  description: string;
  integrationType: IntegrationType;
  hero: React.ReactNode;
}) {
  const next = useMemo<PaginationItem>(() => {
    return {
      label: "Get Started",
      path: getOnboardingRoute({
        type: integrationType,
        onboardingState: "integration",
      }),
    };
  }, [integrationType]);

  const prev = useMemo(
    () => ({
      label: "Select App",
      path: "/onboarding/welcome",
    }),
    []
  );

  return (
    <OnboardingStep
      title={title}
      description={description}
      prev={prev}
      next={next}
      hero={hero}
    ></OnboardingStep>
  );
}
