import { useCallback } from "react";
import { IntegrationType, StripeSubscriptionJSON } from "../types";
import DashboardCard from "./DashboardCard";
import { CLIENT_CONFIG } from "../constants";

interface PaymentHistoryProps {
  userSubscription: StripeSubscriptionJSON;
}

export default function PaymentHistoryCard({
  userSubscription,
}: PaymentHistoryProps) {
  return (
    <DashboardCard
      integrationType={"budget"}
      image={<CLIENT_CONFIG.images.billing.paymentHistoryPanel />}
      title="Payment History"
      subtitle="Record of all past payments."
    >
      some context
    </DashboardCard>
  );
}
