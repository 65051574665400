import OnboardingStep, { PaginationItem } from "../components/OnboardingStep";
import { getOAuthUrl, getOnboardingRoute } from "../utils";
import { useCallback, useContext, useEffect, useMemo } from "react";

import { CLIENT_CONFIG } from "../constants";
import { IntegrationType } from "../types";
import { IntegrationsContext } from "../providers/IntegrationsProvider";
import classnames from "classnames";
import useEventTracking from "../hooks/useEventTracking";

export default function OnboardingIntegration({
  integrationType,
  hero,
}: {
  integrationType: IntegrationType;
  hero: React.ReactNode;
}) {
  const {
    state: { loading },
    currentIntegration,
    updateOnboardingState,
  } = useContext(IntegrationsContext);
  const { trackEvent } = useEventTracking();

  useEffect(() => {
    const integration = currentIntegration(integrationType, {
      discoveryComplete: true,
    });
    if (integration && integration.onboardingState !== "integration") {
      updateOnboardingState(integration.id, "integration");
    }
  }, [currentIntegration]);

  const handleClick = useCallback(() => {
    trackEvent("initiateInstall");
    window.location.href = getOAuthUrl(integrationType);
  }, [integrationType]);

  const integration = currentIntegration(integrationType);

  const NEXT_PAGINATION_ITEM = useMemo<PaginationItem>(
    () => ({
      label: "Subscription",
      path: getOnboardingRoute({
        type: integrationType,
        onboardingState: "billing",
      }),
      disabled: !loading && !integration,
    }),
    [integrationType, integration, loading]
  );

  const PREV_PAGINATION_ITEM = useMemo<PaginationItem>(
    () => ({
      label: "Welcome",
      path: getOnboardingRoute({
        type: integrationType,
        onboardingState: "welcome",
      }),
    }),
    [integrationType, integration, loading]
  );

  return (
    <OnboardingStep
      title="Connect your workspace"
      description={`Add the ${CLIENT_CONFIG.integrationConfig[integrationType].integrationLabel} connection for your Notion workspace. Be sure to opt-in to using the provided ${CLIENT_CONFIG.integrationConfig[integrationType].integrationLabel} template when prompted.`}
      prev={PREV_PAGINATION_ITEM}
      next={NEXT_PAGINATION_ITEM}
      hero={hero}
    >
      <button
        className={classnames("btn btn-primary mt-2")}
        disabled={!!integration}
        onClick={handleClick}
      >
        {!!integration ? "Workspace already connected" : "Connect"}
      </button>
    </OnboardingStep>
  );
}
