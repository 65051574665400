import "cirrus-ui";
import "remixicon/fonts/remixicon.css";
import "./styles.css";
import "./styles.scss";

import {
  AppState,
  Auth0Provider,
  WithAuthenticationRequiredOptions,
  withAuthenticationRequired,
} from "@auth0/auth0-react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { CLIENT_CONFIG, ENVIRONMENT } from "./constants";
import React, { useCallback } from "react";

import AnalyticsProvider from "./providers/AnalyticsProvider";
import BannerProvider from "./providers/BannerProvider";
import BatchJobProvider from "./providers/BatchJobsProvider";
import Billing from "./pages/Billing";
import BudgetDashboard from "./pages/BudgetDashboard";
import ContentContainer from "./components/ContentContainer";
import DataFetchContainer from "./components/DataFetchContainer";
import FeedbackButton from "./components/FeedbackButton";
import { Helmet } from "react-helmet";
import IntegrationInit from "./pages/IntegrationInit";
import IntegrationsProvider from "./providers/IntegrationsProvider";
import LandingPage from "./pages/Landing";
import NavRoutes from "./components/NavRoutes";
import Onboarding from "./pages/Onboarding";
import OnboardingAddAccounts from "./pages/OnboardingAddAccounts";
import OnboardingBilling from "./pages/OnboardingBilling";
import OnboardingBillingSuccess from "./pages/OnboardingBillingSuccess";
import OnboardingCompositeWelcome from "./pages/OnboardingCompositeWelcome";
import OnboardingFinished from "./pages/OnboardingFinished";
import OnboardingIntro from "./pages/OnboardingIntro";
import OnboardingItegration from "./pages/OnboardingIntegration";
import OnboardingItegrationSuccess from "./pages/OnboardingItegrationSuccess";
import OnboardingPreferences from "./pages/OnboardingPreferences";
import PlaidItemProvider from "./providers/PlaidItemsProvider";
import PlaidLink from "./pages/PlaidLink";
import PlaidOAuth from "./pages/PlaidOAuth";
import StockTickersProvider from "./providers/StockTickersProvider";
import StocksDashboard from "./pages/StocksDashboard";
import StripeCancel from "./pages/StripeCancel";
import StripeSuccess from "./pages/StripeSuccess";
import UserInfoProvider from "./providers/UserInfoProvider";
import UserRefresh from "./pages/UserRefresh";
import axios from "axios";
import { getDashboardRoute } from "./utils";

axios.defaults.baseURL = CLIENT_CONFIG.apiBaseUrl;

const OAUTH_PATHS = ["/integration-init"];

const ProtectedRoute = <P extends {}>({
  component,
  componentProps,
  ...options
}: WithAuthenticationRequiredOptions & {
  component: React.FunctionComponent<P>;
  componentProps: P;
}) => {
  const Component = withAuthenticationRequired(component, options);
  return <Component {...componentProps} />;
};

function Auth0ProviderWithRedirectCallback({
  children,
  ...props
}: React.ComponentProps<typeof Auth0Provider>) {
  const navigate = useNavigate();

  const onRedirectCallback = useCallback(
    (appState: AppState | undefined) => {
      navigate(
        (appState && appState.returnTo) ||
          window.location.pathname + window.location.search
      );
    },
    [navigate]
  );

  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
}

function App() {
  return (
    <>
      <Helmet>
        <title>{CLIENT_CONFIG.appLabel}</title>
        <meta name="theme-color" content={CLIENT_CONFIG.themeColor} />
        <link
          rel="icon"
          type="image/png"
          href={CLIENT_CONFIG.favicon}
          sizes="16x16"
        />
        <link
          rel="apple-touch-icon"
          type="image/png"
          href={CLIENT_CONFIG.favicon}
        />
        <script>
          {`!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window,document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
 fbq('init', '2094590370972344'); 
fbq('track', 'PageView');`}
        </script>
        <noscript>
          {`<img height="1" width="1" 
src="https://www.facebook.com/tr?id=2094590370972344&ev=PageView
&noscript=1"/>`}
        </noscript>
      </Helmet>
      <BrowserRouter>
        <Auth0ProviderWithRedirectCallback
          domain={CLIENT_CONFIG.auth0.domain}
          clientId={CLIENT_CONFIG.auth0.clientId}
          audience={CLIENT_CONFIG.auth0.audience}
          redirectUri={window.location.origin}
          cacheLocation="localstorage"
          useRefreshTokens={true}
          skipRedirectCallback={OAUTH_PATHS.includes(window.location.pathname)}
        >
          <BannerProvider>
            <UserInfoProvider>
              <IntegrationsProvider>
                <PlaidItemProvider>
                  <BatchJobProvider>
                    <StockTickersProvider>
                      <AnalyticsProvider>
                        <DataFetchContainer>
                          <NavRoutes />
                          <ContentContainer>
                            <Routes>
                              <Route
                                path="/billing"
                                element={
                                  <ProtectedRoute
                                    component={Billing}
                                    componentProps={{}}
                                  />
                                }
                              />
                              {/* composite onboarding */}
                              <Route
                                path="/onboarding"
                                element={
                                  <ProtectedRoute
                                    component={Onboarding}
                                    componentProps={{}}
                                  />
                                }
                              >
                                <Route
                                  path="welcome"
                                  element={<OnboardingCompositeWelcome />}
                                />
                              </Route>

                              {/* Stocks Onboarding */}
                              {CLIENT_CONFIG.integrationConfig.stocks
                                .enabled && (
                                <Route
                                  path="/stocks/onboarding"
                                  element={
                                    <ProtectedRoute
                                      component={Onboarding}
                                      componentProps={{}}
                                    />
                                  }
                                >
                                  <Route
                                    path="welcome"
                                    element={
                                      <OnboardingIntro
                                        title={`Welcome to ${CLIENT_CONFIG.integrationConfig.stocks.integrationLabel}!`}
                                        description="This app gives you live-updating stock tickers in your Notion workspace. We'll walk you through a few steps to get your workspace set up. Shouldn't take more than a couple of minutes!"
                                        integrationType="stocks"
                                        hero={
                                          <CLIENT_CONFIG.integrationConfig.stocks.stocksImages.onboardingWelcome />
                                        }
                                      />
                                    }
                                  />
                                  <Route
                                    path="integration"
                                    element={
                                      <OnboardingItegration
                                        integrationType="stocks"
                                        hero={
                                          <CLIENT_CONFIG.integrationConfig.stocks.stocksImages.onboardingIntegration />
                                        }
                                      />
                                    }
                                  />
                                  <Route
                                    path="integration-success"
                                    element={
                                      <OnboardingItegrationSuccess
                                        integrationType="stocks"
                                        hero={
                                          <CLIENT_CONFIG.integrationConfig.stocks.stocksImages.onboardingIntegrationSuccess
                                            style={{
                                              maxWidth: "16rem",
                                              margin: "auto",
                                            }}
                                          />
                                        }
                                      />
                                    }
                                  />
                                  <Route
                                    path="billing"
                                    element={
                                      <OnboardingBilling integrationType="stocks" />
                                    }
                                  />
                                  <Route
                                    path="billing-success"
                                    element={
                                      <OnboardingBillingSuccess integrationType="stocks" />
                                    }
                                  />
                                  <Route
                                    path="finished"
                                    element={
                                      <OnboardingFinished
                                        integrationType="stocks"
                                        hero={
                                          <CLIENT_CONFIG.integrationConfig.stocks.stocksImages.onboardingFinished />
                                        }
                                      />
                                    }
                                  />
                                </Route>
                              )}

                              {/* Budget Onboarding */}
                              {CLIENT_CONFIG.integrationConfig.budget
                                .enabled && (
                                <Route
                                  path="budget/onboarding"
                                  element={
                                    <ProtectedRoute
                                      component={Onboarding}
                                      componentProps={{}}
                                    />
                                  }
                                >
                                  <Route
                                    path="welcome"
                                    element={
                                      <OnboardingIntro
                                        title={`Welcome to ${CLIENT_CONFIG.integrationConfig.budget.integrationLabel}!`}
                                        description="This app let's you sync your personal financial data with your Notion workspace! We'll walk you through a few steps to get your workspace set up. Shouldn't take more than a couple of minutes!"
                                        integrationType="budget"
                                        hero={
                                          <CLIENT_CONFIG.integrationConfig.budget.budgetImages.onboardingWelcome />
                                        }
                                      />
                                    }
                                  />
                                  <Route
                                    path="integration"
                                    element={
                                      <OnboardingItegration
                                        integrationType="budget"
                                        hero={
                                          <CLIENT_CONFIG.integrationConfig.budget.budgetImages.onboardingIntegration />
                                        }
                                      />
                                    }
                                  />
                                  <Route
                                    path="integration-success"
                                    element={
                                      <OnboardingItegrationSuccess
                                        integrationType="budget"
                                        hero={
                                          <CLIENT_CONFIG.integrationConfig.budget.budgetImages.onboardingIntegrationSuccess
                                            style={{
                                              maxWidth: "16rem",
                                              margin: "auto",
                                            }}
                                          />
                                        }
                                      />
                                    }
                                  />
                                  <Route
                                    path="billing"
                                    element={
                                      <OnboardingBilling integrationType="budget" />
                                    }
                                  />
                                  <Route
                                    path="billing-success"
                                    element={
                                      <OnboardingBillingSuccess integrationType="budget" />
                                    }
                                  />
                                  <Route
                                    path="preferences"
                                    element={<OnboardingPreferences />}
                                  />
                                  <Route
                                    path="accounts"
                                    element={<OnboardingAddAccounts />}
                                  />
                                  <Route
                                    path="finished"
                                    element={
                                      <OnboardingFinished
                                        integrationType="budget"
                                        hero={
                                          <CLIENT_CONFIG.integrationConfig.budget.budgetImages.onboardingFinished />
                                        }
                                      />
                                    }
                                  />
                                </Route>
                              )}

                              {/* Other Stocks Routes */}
                              {CLIENT_CONFIG.integrationConfig.stocks
                                .enabled && (
                                <>
                                  <Route
                                    path="/stocks/integration-init"
                                    element={
                                      <ProtectedRoute
                                        component={IntegrationInit}
                                        componentProps={{
                                          integrationType: "stocks",
                                          hero: (
                                            <CLIENT_CONFIG.integrationConfig.stocks.stocksImages.onboardingDiscoveryInProgress
                                              style={{
                                                width: "14rem",
                                                margin: "auto",
                                              }}
                                            />
                                          ),
                                        }}
                                      />
                                    }
                                  />
                                  <Route
                                    path={getDashboardRoute({ type: "stocks" })}
                                    element={
                                      <ProtectedRoute
                                        component={StocksDashboard}
                                        componentProps={{}}
                                      />
                                    }
                                  />
                                </>
                              )}

                              {/* Other Budget Routes */}
                              {CLIENT_CONFIG.integrationConfig.budget
                                .enabled && (
                                <>
                                  <Route
                                    path="/integration-init"
                                    element={
                                      <ProtectedRoute
                                        component={IntegrationInit}
                                        componentProps={{
                                          integrationType: "budget",
                                          hero: (
                                            <CLIENT_CONFIG.integrationConfig.budget.budgetImages.onboardingDiscoveryInProgress
                                              style={{
                                                width: "14rem",
                                                margin: "auto",
                                              }}
                                            />
                                          ),
                                        }}
                                      />
                                    }
                                  />
                                  <Route
                                    path="/plaid-oauth"
                                    element={
                                      <ProtectedRoute
                                        component={PlaidOAuth}
                                        componentProps={{}}
                                      />
                                    }
                                  />
                                  <Route
                                    path="/plaid-link"
                                    element={
                                      <ProtectedRoute
                                        component={PlaidLink}
                                        componentProps={{}}
                                      />
                                    }
                                  />
                                  <Route
                                    path={getDashboardRoute({ type: "budget" })}
                                    element={
                                      <ProtectedRoute
                                        component={BudgetDashboard}
                                        componentProps={{}}
                                      />
                                    }
                                  />
                                </>
                              )}

                              {/* Non-protected routes */}
                              <Route
                                path="/user-refresh"
                                element={<UserRefresh />}
                              />
                              <Route
                                path="/stripe-success"
                                element={
                                  <StripeSuccess integrationType="budget" />
                                }
                              />
                              <Route
                                path="/stocks/stripe-success"
                                element={
                                  <StripeSuccess integrationType="stocks" />
                                }
                              />
                              <Route
                                path="/stripe-cancel"
                                element={<StripeCancel />}
                              />
                              <Route path="/" element={<LandingPage />} />
                            </Routes>
                          </ContentContainer>
                          <FeedbackButton />
                        </DataFetchContainer>
                      </AnalyticsProvider>
                    </StockTickersProvider>
                  </BatchJobProvider>
                </PlaidItemProvider>
              </IntegrationsProvider>
            </UserInfoProvider>
          </BannerProvider>
        </Auth0ProviderWithRedirectCallback>
      </BrowserRouter>
      <div
        id="portal"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          overflow: "visible",
        }}
      />
    </>
  );
}

export default App;
