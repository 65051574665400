import { IntegrationType } from "../types";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { getOnboardingRoute } from "../utils";
import { CLIENT_CONFIG } from "../constants";

export default function IntegrationPreview({
  integrationType,
  className,
}: {
  integrationType: IntegrationType;
  className?: string;
}) {
  const navigate = useNavigate();
  return (
    <button
      className={classNames(
        `border-${CLIENT_CONFIG.integrationConfig[integrationType].primaryColorPrefix}-500 bg-${CLIENT_CONFIG.integrationConfig[integrationType].primaryColorPrefix}-100`,
        className
      )}
      style={{ maxWidth: 250 }}
      onClick={() =>
        navigate(
          getOnboardingRoute({
            type: integrationType,
            onboardingState: "welcome",
          })
        )
      }
    >
      <div
        className="content u-inline-flex u-flex-column u-align-center u-justify-flex-start pt-3"
        style={{ alignItems: "center" }}
      >
        <img
          src={CLIENT_CONFIG.integrationConfig[integrationType].logo}
          width={50}
          className="mr-1"
        />
        <p id="projectname" className="title mt-2 mb-0">
          {CLIENT_CONFIG.integrationConfig[integrationType].label}
        </p>
        <p style={{ textTransform: "none" }}>
          {CLIENT_CONFIG.integrationConfig[integrationType].description}
        </p>
      </div>
    </button>
  );
}
