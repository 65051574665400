import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function StripeCancel() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/", { replace: true });
  }, []);
  return <div className="animated loading m-2" style={{ height: 40 }}></div>;
}
