import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

export default function UserRefresh() {
  const { getAccessTokenSilently, isLoading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) {
      return;
    }
    async function refresh() {
      await getAccessTokenSilently({ ignoreCache: true });
      navigate("/");
    }

    refresh();
  }, [isLoading, getAccessTokenSilently, navigate]);
  return <div className="animated loading m-2" style={{ height: 40 }}></div>;
}
