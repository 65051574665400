import classnames from "classnames";
import { useContext } from "react";
import styled from "styled-components";
import { BannerContext } from "../providers/BannerProvider";
import { isMediumOrLarger } from "../utils";

const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  margin: auto;
  padding-top: 3.5rem;
  padding-bottom: 2rem;
`;

const InnerContainer = styled.div`
  margin: auto;
  max-width: 1000px;
`;

export default function ContentContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  const {
    state: { banners },
    dismissBanners,
  } = useContext(BannerContext);

  return (
    <Container className="pt-6-md">
      {banners.map(({ id, type, text, dismissible, translucent }) => (
        <div
          key={id}
          className={classnames(
            "toast",
            "u-sticky",
            translucent && "toast--translucent",
            "m-0",
            type && `toast--${type}`,
            "animated fadeIn"
          )}
          style={{ top: isMediumOrLarger() ? "3rem" : "3.5rem", zIndex: 1 }}
        >
          {dismissible && (
            <button
              onClick={() => dismissBanners([id])}
              className="btn-close"
            ></button>
          )}
          <p>{text}</p>
        </div>
      ))}
      <InnerContainer className="pt-1 px-1 pt-4-md px-4-md">
        {children}
      </InnerContainer>
    </Container>
  );
}
