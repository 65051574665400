import React from "react";

export type AppID = "nofi" | "nischa";
export type Environment = "development" | "production" | "test";

export type IntegrationType = "budget" | "stocks";
export type IntegrationPayloadType<T extends IntegrationType> =
  T extends "budget"
    ? BudgetIntegrationJSON
    : T extends "stocks"
    ? StocksIntegrationJSON
    : never;

export type GetPlaidItemsResponse = NofiAPIResponse<{
  items: Array<PlaidItemJSON>;
}>;

export type GetBudgetCategoriesResponse = NofiAPIResponse<{
  budgetCategories: Array<BudgetCategoryJSON>;
}>;

export type CreateUserFeedbackResponse = NofiAPIResponse<{
  success: boolean;
}>;

export type ExchangePublicTokenResponse = NofiAPIResponse<{
  item: PlaidItemJSON;
}>;

export type UpdateTransactionCategoriesResponse = NofiAPIResponse<{
  transactionCategories: Array<NotionSelectOption>;
}>;

export type DeletePlaidItemResponse = NofiAPIResponse<{}>;
export type DeleteBudgetCategoryResponse = NofiAPIResponse<{}>;
export type CreateBudgetCategoryResponse = NofiAPIResponse<{}>;
export type UpdateBudgetCategoryResponse = NofiAPIResponse<{}>;

export type RefreshPlaidItemResponse = NofiAPIResponse<{
  transactionSyncJobId: string;
  accountSyncJobId: string;
}>;

export type GetBatchJobsResponse = NofiAPIResponse<{
  jobs: Array<BatchJobJSON>;
}>;

export type GetUserInfoResponse = NofiAPIResponse<{
  userInfo: UserJSON;
}>;

export type GetIntegrationsResponse = NofiAPIResponse<{
  integrations: Array<BudgetIntegrationJSON>;
}>;

export type PostNotionOAuthResponse = NofiAPIResponse<{
  integration: BudgetIntegrationJSON;
}>;

export type UpdateOnboardingStateResponse = NofiAPIResponse<{
  onboardingState: OnboardingState;
}>;

export type SubmitEarlyAccessCodeResponse = NofiAPIResponse<{
  userSubscription: StripeSubscriptionJSON;
}>;

export type UserSubscriptionResponse = NofiAPIResponse<{
  userSubscription: StripeSubscriptionJSON;
}>;

export type GetUserSubscriptionResponse = NofiAPIResponse<{
  userSubscription: StripeSubscriptionJSON | null;
}>;

export interface PlaidItemJSON {
  accessToken: string;
  userId: string;
  accounts: Array<PlaidAccountJSON>;
  itemId: string;
  transactionsCursor: string | null;
  institutionId: string | null;
  institutionName: string | null;
  lastTransactionSyncJobId: string | null;
  deleted: boolean;
  errorCode: string | null;
}

export interface PlaidAccountJSON {
  id: string;
  pageId: string | null;
  name: string;
  type: string;
  mask: string | null;
  currentBalance: number | null;
  availableBalance: number | null;
}

export interface UserJSON {
  id: string;
  email: string;
  name: string | null;
  transactionStartDate: number | null;
  /**
   * @deprecated use StripeSubscription instead.
   */
  subscriptionId: string | null;
  /**
   * @deprecated
   */
  subscriptionPeriodEnd: number | null;
  /**
   * @deprecated
   */
  stripeSessionId: string | null;
  /**
   * @deprecated
   */
  onboardingComplete: boolean;
  plaidLinkToken: string | null;
}

export interface StripeSubscriptionJSON {
  userId: string;
  id: string;
  customerId: string;
  subscriptionPeriodEnd: number;
  stripeSessionId: string | null;
  items: Array<StripeSubscriptionItemJSON>;
}

export interface StripeSubscriptionItemJSON {
  integrationType: IntegrationType;
  priceId: string;
}

export enum JobStatus {
  FAILED = "FAILED",
  PENDING = "PENDING",
  RUNNABLE = "RUNNABLE",
  RUNNING = "RUNNING",
  STARTING = "STARTING",
  SUBMITTED = "SUBMITTED",
  SUCCEEDED = "SUCCEEDED",
}

export interface BatchJobJSON {
  id: string;
  name: string;
  status: JobStatus;
  startedAt: number | null;
  stoppedAt: number | null;
}

export interface PagePropertyBase {
  id: string;
  type: "base";
}

export interface PagePropertyWithOptions {
  id: string;
  type: "select";
  options: { [optionLabel: string]: string | null };
}

export type PageProperty = PagePropertyBase | PagePropertyWithOptions;
export type PagePropertiesJSON = Record<string, PageProperty | null>;

export declare type SelectColor =
  | "default"
  | "gray"
  | "brown"
  | "orange"
  | "yellow"
  | "green"
  | "blue"
  | "purple"
  | "pink"
  | "red";

export interface NotionSelectOption {
  name: string;
  id: string;
  color: SelectColor;
}

export interface BaseIntegrationJSON {
  type: IntegrationType;
  id: string;
  userId: string;
  accessToken: string;
  rootPageId: string | null;
  rootPageUrl: string | null;
  workspaceIcon: string | null;
  workspaceName: string | null;
  workspaceId: string;
  onboardingState: OnboardingState;
  deleted: boolean;
}

export type OnboardingState =
  | "welcome"
  | "integration"
  | "integration-success"
  | "billing"
  | "billing-success"
  | "preferences"
  | "accounts"
  | "complete";
export interface BudgetIntegrationJSON extends BaseIntegrationJSON {
  type: "budget";
  onboardingState: OnboardingState;
  template: NotionBudgetTemplateJSON;
}

export interface StocksIntegrationJSON extends BaseIntegrationJSON {
  type: "stocks";
  onboardingState: OnboardingState;
  template: NotionStocksTemplateJSON;
}

export interface NotionStocksTemplateJSON extends NotionTemplateJSON {
  tickersDb: NotionDatabaseJSON | null;
}

export interface NotionBudgetTemplateJSON extends NotionTemplateJSON {
  budgetCategoriesDb: NotionDatabaseJSON | null;
  monthlySummariesDb: NotionDatabaseJSON | null;
  transactionsDb: NotionDatabaseJSON | null;
  accountsDb: NotionDatabaseJSON | null;
}

export type NotionDatabasePropertySchema =
  | NotionSimpleDatabasePropertySchema
  | NotionSelectDatabasePropertySchema;
interface NotionSimpleDatabasePropertySchema {
  type:
    | "title"
    | "rich_text"
    | "formula"
    | "rollup"
    | "relation"
    | "checkbox"
    | "date"
    | "number";
}
interface NotionSelectDatabasePropertySchema {
  type: "select" | "multi_select";
  options: Set<string>;
}

export type NotionTemplateTag =
  | "root_page"
  | "accounts_db"
  | "transactions_db"
  | "budget_categories_db"
  | "monthly_summaries_db"
  | "tickers_db";

interface NotionPageSchema {
  tag?: NotionTemplateTag;
  type: "page";
  title: string;
  children: Array<NotionBlockSchema>;
}

interface NotionDatabaseSchema {
  tag?: NotionTemplateTag;
  type: "database";
  title: string;
  properties: { [propertyName: string]: NotionDatabasePropertySchema };
}

export type NotionBlockSchema = NotionDatabaseSchema | NotionPageSchema;

export type NotionTemplateDiscoveryState = "NOT_STARTED" | "ERROR" | "COMPLETE";

export interface NotionTemplateJSON {
  schema: NotionBlockSchema;
  rootPageId: string;
  discoveryState: NotionTemplateDiscoveryState;
}

export interface NotionDatabaseJSON {
  id: string;
  properties: NotionDatabasePageProperties;
}

export type NotionDatabasePageProperties = {
  [propertyName: string]: NotionPageProperty;
};

interface NotionPageProperty {
  propertyId: string;
  options?: Array<NotionSelectOption>;
}

export interface BudgetCategoryJSON {
  /**
   * ID of the budget category. UUID that gets generated on creation.
   */
  id: string;
  /**
   * User ID of the owner of this budget category
   */
  userId: string;
  /**
   * The transaction category name that will be used to track spend.
   */
  categoryName: string;
  /**
   * Property ID on the budgets database. "<categoryName> Budgeted"
   */
  budgetedPropertyId: string | null;
  /**
   * Property ID on the budgets database. "<categoryName> Spent"
   */
  spentPropertyId: string | null;
  /**
   * Property ID on the budgets database. "<categoryName>"
   */
  progressPropertyId: string | null;
  /**
   * Property ID on the transactions database. "<categoryName> Amount"
   */
  transactionAmountPropertyId: string | null;
  /**
   * Budgeted amount per period for this category. Will be used to populate new record in budgets DB.
   */
  budgeted: number;
  /**
   * Whether or not this budget category has been deleted.
   */
  deleted?: boolean;
}

export interface Banner {
  id: string;
  type: "info" | "danger" | "warning" | "success";
  text: string | React.ReactNode;
  dismissible?: boolean;
  translucent?: boolean;
  ttl?: number;
}

export interface ModalAction {
  label: string | React.ReactNode;
  onClick: "dismiss" | (() => void);
  type:
    | "primary"
    | "light"
    | "dark"
    | "link"
    | "info"
    | "danger"
    | "warning"
    | "success";
  outline?: boolean;
}
export interface Modal {
  id: string;
  type:
    | "primary"
    | "light"
    | "dark"
    | "link"
    | "info"
    | "danger"
    | "warning"
    | "success";
  content: string | React.ReactNode;
  actions: Array<ModalAction>;
}

export type NofiAPIResponse<TPayload> =
  | (TPayload & { success: true })
  | { success: false; error: unknown; message: string };

export interface StockTickerJSON {
  name: string;
  ticker: string;
  iconUrl: string | null;
  currencyName: string | null;
  description: string | null;
  homepageUrl: string | null;
  marketCap: number | null;
  lastPrice: number | null;
  profitToEarnings: number | null;
  dayOpen: number | null;
  dayClose: number | null;
  dayVolume: number | null;
  dayHigh: number | null;
  dayLow: number | null;
}
