interface FeedbackModalProps {
  modalId: string;
  title: string | React.ReactNode;
  children: React.ReactNode;
  onConfirm: (e: React.MouseEvent<HTMLElement>) => void;
  cancelLabel?: string;
  confirmLabel?: string;
}

export default function FeedbackModal({
  title,
  children,
  modalId,
  onConfirm,
  cancelLabel,
  confirmLabel,
}: FeedbackModalProps) {
  return (
    <div className="modal modal-animated--zoom-in" id={modalId}>
      <a href="#" className="modal-overlay close-btn" aria-label="Close"></a>
      <div className="modal-content" role="document">
        <div className="modal-header">
          <h3 className="modal-title">{title}</h3>
        </div>
        <div className="modal-body">{children}</div>
        <div className="modal-footer">
          <div className="form-section u-text-right">
            <a href="#">
              <button className="btn btn--sm u-inline-block">
                {cancelLabel || "Cancel"}
              </button>
            </a>
            <button
              onClick={onConfirm}
              className="btn-danger btn--sm u-inline-block"
            >
              {confirmLabel || "Confirm"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
