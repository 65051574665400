import classNames from "classnames";
import styled from "styled-components";
import { IntegrationType } from "../types";
import { CLIENT_CONFIG } from "../constants";

interface ComponentProps {
  children?: React.ReactNode;
  image: React.ReactNode;
  title: string;
  subtitle: string;
  buttonContent?: React.ReactNode;
  secondaryButtonContent?: React.ReactNode;
  onSecondaryButtonClick?: () => void;
  onButtonClick?: () => void;
  buttonDisabled?: boolean;
  secondaryButtonDisabled?: boolean;
  secondaryButtonTooltip?: string;
  integrationType: IntegrationType;
}

const MobileImageContainer = styled.div`
  max-width: 50px;
`;

export default function DashboardCard({
  children,
  image,
  title,
  subtitle,
  buttonContent,
  onButtonClick,
  secondaryButtonContent,
  onSecondaryButtonClick,
  buttonDisabled,
  secondaryButtonDisabled,
  secondaryButtonTooltip,
  integrationType,
}: ComponentProps) {
  return (
    <div className="card">
      <div className="m-2">
        <div className="tile">
          <div className="tile__icon u-none u-flex-sm">
            <figure
              className={`avatar u-flex u-items-center u-justify-center bg-white u-border-2 border-${CLIENT_CONFIG.integrationConfig[integrationType].primaryColorPrefix}-500`}
            >
              {image}
            </figure>
          </div>
          <div className="tile__container px-0 px-2-sm">
            <MobileImageContainer className="u-none-sm">
              {image}
            </MobileImageContainer>
            <p className="tile__title m-0 text-lg">{title}</p>
            <p className="tile__subtitle m-0">{subtitle}</p>
            {buttonContent && (
              <div className="tile__buttons btn-group m-0 u-none-sm mt-2">
                <button
                  onClick={onButtonClick}
                  disabled={buttonDisabled}
                  className={classNames(
                    "btn-primary btn--sm uppercase font-semibold u-flex u-items-center",
                    buttonDisabled && "btn--disabled"
                  )}
                >
                  {buttonContent}
                </button>
                {secondaryButtonContent && (
                  <button
                    onClick={onSecondaryButtonClick}
                    disabled={secondaryButtonDisabled}
                    data-tooltip={secondaryButtonTooltip}
                    className={classNames(
                      "btn-secondary btn--sm uppercase font-semibold u-flex u-items-center",
                      secondaryButtonDisabled && "btn--disabled"
                    )}
                  >
                    {secondaryButtonContent}
                  </button>
                )}
              </div>
            )}
          </div>
          {buttonContent && (
            <div className="tile__buttons btn-group m-0 u-none u-flex-sm u-items-center">
              <button
                onClick={onButtonClick}
                disabled={buttonDisabled}
                className={classNames(
                  "btn-primary btn--sm uppercase font-semibold u-flex u-items-center",
                  buttonDisabled && "btn--disabled"
                )}
              >
                {buttonContent}
              </button>
              {secondaryButtonContent && (
                <button
                  onClick={onSecondaryButtonClick}
                  disabled={secondaryButtonDisabled}
                  data-tooltip={secondaryButtonTooltip}
                  className={classNames(
                    "btn-secondary btn--sm uppercase font-semibold u-flex u-items-center",
                    secondaryButtonDisabled && "btn--disabled"
                  )}
                >
                  {secondaryButtonContent}
                </button>
              )}
            </div>
          )}
        </div>
        {children}
      </div>
    </div>
  );
}
