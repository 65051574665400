import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserInfoContext } from "../providers/UserInfoProvider";
import { IntegrationsContext } from "../providers/IntegrationsProvider";
import { PlaidItemContext } from "../providers/PlaidItemsProvider";
import useAuth from "../hooks/useAuth";
import { getDashboardRoute, getOnboardingRoute } from "../utils";

export default function Landing() {
  const navigate = useNavigate();
  const {
    loginWithRedirect,
    isAuthenticated,
    isLoading: auth0UserLoading,
    user,
  } = useAuth();

  const {
    state: { currentUser, userSubscription },
  } = useContext(UserInfoContext);
  const {
    state: { integrations, loading: integrationsLoading },
  } = useContext(IntegrationsContext);
  const {
    state: { items, loading: plaidItemsLoading },
  } = useContext(PlaidItemContext);

  useEffect(() => {
    // still loading, do nothing.
    if (auth0UserLoading) {
      return;
    }

    // user not logged in. redirect to login.
    if (!isAuthenticated) {
      loginWithRedirect();
      return;
    }

    // wait for rest of data to load
    if (!currentUser || integrationsLoading || plaidItemsLoading) {
      return;
    }

    // if user has no integrations, redirect to composite welcome
    if (!integrations.length) {
      navigate("/onboarding/welcome", { replace: true });
      return;
    }

    // if user has an integration with a non-complete onboarding state, redirect to appropriate onboarding screen.
    const unfinishedIntegrations = integrations.filter(
      (integration) => integration.onboardingState !== "complete"
    );
    if (unfinishedIntegrations.length) {
      navigate(getOnboardingRoute(unfinishedIntegrations[0]));
      return;
    }

    navigate(getDashboardRoute({ type: integrations[0].type }), {
      replace: true,
    });
  }, [
    isAuthenticated,
    auth0UserLoading,
    integrationsLoading,
    plaidItemsLoading,
    currentUser,
    userSubscription,
    items,
    integrations,
    loginWithRedirect,
  ]);

  return <div></div>;
}
