import { Route, Routes } from "react-router-dom";
import AuthNav from "./AuthNav";
import LandingNav from "./LandingNav";
import { useContext } from "react";
import { IntegrationsContext } from "../providers/IntegrationsProvider";

export default function NavRoutes() {
  const {
    state: { hasSomeCompletedIntegration },
  } = useContext(IntegrationsContext);

  return (
    <Routes>
      <Route path="/:integrationType/dashboard" element={<AuthNav />} />
      <Route path="/billing" element={<AuthNav />} />
      <Route
        path="/:integrationType/onboarding/*"
        element={<AuthNav disableNavigation={!hasSomeCompletedIntegration} />}
      />
      <Route
        path="/:integrationType/integration-init"
        element={<AuthNav disableNavigation />}
      />
      <Route
        path="/onboarding/welcome"
        element={<AuthNav disableNavigation={!hasSomeCompletedIntegration} />}
      />
      <Route path="/" element={<LandingNav />} />
    </Routes>
  );
}
