import { useContext, useEffect, useMemo } from "react";
import OnboardingStep, { PaginationItem } from "../components/OnboardingStep";
import {
  getOnboardingRoute,
  isUserSubscribedToIntegration,
  isUserSubscriptionValid,
} from "../utils";
import { UserInfoContext } from "../providers/UserInfoProvider";
import { IntegrationType } from "../types";
import { IntegrationsContext } from "../providers/IntegrationsProvider";
import { CLIENT_CONFIG } from "../constants";

export default function OnboardingBillingSuccess({
  integrationType,
}: {
  integrationType: IntegrationType;
}) {
  const {
    state: { currentUser, userSubscription },
  } = useContext(UserInfoContext);

  const { currentIntegration, updateOnboardingState } =
    useContext(IntegrationsContext);

  useEffect(() => {
    const integration = currentIntegration(integrationType, {
      discoveryComplete: true,
    });
    if (!integration || integration.onboardingState === "billing-success") {
      return;
    }

    updateOnboardingState(integration.id, "billing-success");
  }, [currentIntegration]);

  const subscriptionValid: boolean =
    !!currentUser &&
    currentUser &&
    isUserSubscriptionValid(userSubscription) &&
    isUserSubscribedToIntegration(userSubscription!, integrationType);

  const NEXT_PAGINATION_ITEM = useMemo<PaginationItem>(
    () => ({
      label: "Preferences",
      path: getOnboardingRoute({
        type: "budget",
        onboardingState: "preferences",
      }),
      disabled: !subscriptionValid,
    }),
    [subscriptionValid, integrationType]
  );

  return (
    <OnboardingStep
      title="Good to Go!"
      description={
        "Your free trial has been activated and you are ready to get started. Configure your preferences and add your accounts on the next pages."
      }
      prev={null}
      next={NEXT_PAGINATION_ITEM}
      hero={
        <CLIENT_CONFIG.integrationConfig.budget.budgetImages.onboardingBillingSuccess
          style={{ maxWidth: "16rem", margin: "auto" }}
        />
      }
    ></OnboardingStep>
  );
}
