import { useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { UserInfoContext } from "../providers/UserInfoProvider";
import { IntegrationsContext } from "../providers/IntegrationsProvider";
import { IntegrationType, OnboardingState } from "../types";
import { getOnboardingRoute } from "../utils";

export default function StripeSuccess({
  integrationType,
}: {
  integrationType: IntegrationType;
}) {
  const navigate = useNavigate();
  const {
    state: { userSubscription },
  } = useContext(UserInfoContext);

  const { currentIntegration } = useContext(IntegrationsContext);

  const targetIntegration = useMemo(
    () => currentIntegration(integrationType, { discoveryComplete: true }),
    [currentIntegration, integrationType]
  );

  const nextOnboardingState = useMemo<OnboardingState>(() => {
    switch (integrationType) {
      case "budget": {
        return "billing-success";
      }
      case "stocks": {
        return "complete";
      }
    }
  }, [integrationType]);

  useEffect(() => {
    // userSubscription still loading or non-existent.
    if (!userSubscription) {
      return;
    }

    // no subscription items for this integration type yet
    if (
      userSubscription.items.findIndex(
        (item) => item.integrationType === integrationType
      ) === -1
    ) {
      return;
    }

    // no integration for this integrationType has loaded/exists
    if (!targetIntegration) {
      return;
    }

    navigate(
      getOnboardingRoute({
        type: integrationType,
        onboardingState: nextOnboardingState,
      }),
      { replace: true }
    );
  }, [userSubscription, targetIntegration]);

  return <div className="animated loading m-2" style={{ height: 40 }}></div>;
}
