import axios, { AxiosResponse } from "axios";
import React, { useCallback, useContext, useState } from "react";

import useAuth from "../hooks/useAuth";
import { BannerContext } from "../providers/BannerProvider";
import { CreateUserFeedbackResponse } from "../types";
import { createRequestConfig } from "../utils";
import FeedbackModal from "./modals/FeedbackModal";

export default function FeedbackButton() {
  const { getAccessTokenSilently, isAuthenticated } = useAuth();
  const [contents, setContents] = useState("");
  const modalId: string = "feedback";

  const { pushBanners } = useContext(BannerContext);

  const handleConfirm = useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      const res: AxiosResponse<CreateUserFeedbackResponse> = await axios.post(
        "/create-user-feedback",
        {
          contents,
        },
        createRequestConfig(await getAccessTokenSilently())
      );

      if (res.data.success) {
        setContents("");
        pushBanners([
          {
            text: "We've received your feedback, Thanks for submitting!",
            type: "success",
            dismissible: true,
          },
        ]);

        document.getElementById("hidden-close")?.click();
      } else {
        pushBanners([
          {
            text: "Unable to send feedback at this time. Please try again later.",
            type: "danger",
            dismissible: true,
          },
        ]);
      }
    },
    [contents, pushBanners]
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setContents(e.target.value);
    },
    []
  );
  return (
    <>
      {isAuthenticated && (
        <a
          className="btn btn--circle text-lg u-fixed u-center"
          style={{ bottom: "1rem", right: "1rem" }}
          href={`#${modalId}`}
        >
          ?
        </a>
      )}
      <FeedbackModal
        modalId={modalId}
        title="Questions/Feedback?"
        onConfirm={handleConfirm}
        confirmLabel="Submit"
      >
        <p className="text-md">
          Have questions or feedback? Let us know. This is an important part of
          making NoFi better 🙂.
        </p>
        <textarea onChange={handleChange} maxLength={2000} value={contents} />
      </FeedbackModal>
      <a href="#" id="hidden-close" />
    </>
  );
}
