import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";

export default function useAuth() {
  const {
    getAccessTokenSilently: unsafeGetAccessTokenSilently,
    loginWithRedirect,
    ...rest
  } = useAuth0();

  const getAccessTokenSilently = useCallback(
    async ({ ignoreCache }: { ignoreCache?: boolean } = {}) => {
      try {
        return await unsafeGetAccessTokenSilently({ ignoreCache });
      } catch (e: any) {
        if (e.error === "login_required") {
          console.log("login_requred");
          loginWithRedirect();
        }
        if (e.error === "consent_required") {
          console.log("consent_requred");
          loginWithRedirect();
        }
        throw e;
      }
    },
    [unsafeGetAccessTokenSilently, loginWithRedirect]
  );

  return {
    getAccessTokenSilently,
    loginWithRedirect,
    ...rest,
  };
}
