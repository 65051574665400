import OnboardingStep, { PaginationItem } from "../components/OnboardingStep";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  getOnboardingRoute,
  isUserSubscribedToIntegration,
  isUserSubscriptionValid,
} from "../utils";

import { CLIENT_CONFIG } from "../constants";
import { IntegrationType } from "../types";
import { IntegrationsContext } from "../providers/IntegrationsProvider";
import { UserInfoContext } from "../providers/UserInfoProvider";
import useEventTracking from "../hooks/useEventTracking";
import { useNavigate } from "react-router-dom";

export default function OnboardingBilling({
  integrationType,
}: {
  integrationType: IntegrationType;
}) {
  const {
    state: { currentUser, userSubscription },
    createCheckoutSession,
    submitEarlyAccessCode,
    subscribeToIntegration,
  } = useContext(UserInfoContext);

  const {
    state: { hasSomeCompletedIntegration },
    currentIntegration,
    updateOnboardingState,
  } = useContext(IntegrationsContext);

  const navigate = useNavigate();

  const subscriptionValid: boolean =
    !!currentUser &&
    currentUser &&
    isUserSubscriptionValid(userSubscription) &&
    isUserSubscribedToIntegration(userSubscription!, integrationType);

  const initialSubscriptionValid = useRef<boolean>(subscriptionValid);

  useEffect(() => {
    const integration = currentIntegration(integrationType, {
      discoveryComplete: true,
    });
    if (!integration || integration.onboardingState === "billing") {
      return;
    }
    updateOnboardingState(integration.id, "billing");
  }, [currentIntegration]);

  const NEXT_PAGINATION_ITEM = useMemo<PaginationItem>(() => {
    switch (integrationType) {
      case "budget": {
        return {
          label: "Preferences",
          path: getOnboardingRoute({
            type: integrationType,
            onboardingState: "preferences",
          }),
          disabled: !subscriptionValid,
        };
      }
      case "stocks": {
        return {
          label: "Dashboard",
          path: getOnboardingRoute({
            type: integrationType,
            onboardingState: "complete",
          }),
        };
      }
    }
  }, [subscriptionValid, integrationType]);
  const PREV_PAGINATION_ITEM = useMemo<PaginationItem>(
    () => ({
      label: "Connect",
      path: getOnboardingRoute({
        type: integrationType,
        onboardingState: "integration",
      }),
    }),
    [integrationType]
  );

  const [enterInputVisible, setEnterInputVisible] = useState(false);
  const [earlyAccessCodeSubmitted, setEarlyAccessCodeSubmitted] =
    useState(false);
  const [codeValue, setCodeValue] = useState("");

  const { trackEvent } = useEventTracking();

  // if user submitted code and UserInfoContext has updated to reflect a valid subscription
  // for budget early access users only
  useEffect(() => {
    if (
      earlyAccessCodeSubmitted &&
      currentUser &&
      isUserSubscriptionValid(userSubscription)
    ) {
      navigate(
        getOnboardingRoute({
          type: "budget",
          onboardingState: "billing-success",
        })
      );
    }
  }, [earlyAccessCodeSubmitted, currentUser, userSubscription]);

  const handleEnterCodeClick = useCallback(() => {
    setEnterInputVisible(true);
  }, []);

  const handleCodeSubmit = useCallback(async () => {
    const success = await submitEarlyAccessCode(codeValue);
    if (success) {
      setEarlyAccessCodeSubmitted(true);
    }
  }, [submitEarlyAccessCode, codeValue, navigate]);

  const handleCodeValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCodeValue(e.target.value);
    },
    []
  );

  const handleSubscribeClick = useCallback(() => {
    trackEvent("initiateCheckout");
    if (userSubscription) {
      // if user has a subscription, update it with new item
      subscribeToIntegration(integrationType);
    } else {
      // o/w, create a checkout session
      createCheckoutSession(integrationType);
    }
  }, [integrationType, userSubscription]);

  // Occurs when the user is adding additional subscription items to an existing subscription.
  useEffect(() => {
    if (!initialSubscriptionValid.current && subscriptionValid) {
      switch (integrationType) {
        case "budget": {
          navigate(
            getOnboardingRoute({
              type: integrationType,
              onboardingState: "billing-success",
            })
          );
          break;
        }
        case "stocks": {
          navigate(
            getOnboardingRoute({
              type: integrationType,
              onboardingState: "complete",
            })
          );
        }
      }
    }
  }, [integrationType, subscriptionValid]);

  return (
    <OnboardingStep
      title={
        hasSomeCompletedIntegration
          ? "Update your supscription"
          : "Add your billing info"
      }
      description={
        hasSomeCompletedIntegration
          ? "No need to provide payment details. We'll just add this app onto your existing subscription. Cancel at anytime."
          : `${CLIENT_CONFIG.appLabel} is free for the first 30 days. After that, we charge a small monthly fee for our services. Cancel at anytime.`
      }
      prev={PREV_PAGINATION_ITEM}
      next={NEXT_PAGINATION_ITEM}
      hero={
        <CLIENT_CONFIG.integrationConfig.budget.budgetImages.onboardingBilling
          style={{ width: "17rem", margin: "auto" }}
        />
      }
    >
      {enterInputVisible ? (
        <div className="animated fadeIn">
          <input
            type="text"
            value={codeValue}
            onChange={handleCodeValueChange}
            placeholder="Early Access Code"
            className="input--sm mt-2"
            style={{ maxWidth: 300 }}
          />
          <div className="animated fadein">
            <button
              key="cancel-2"
              className="btn m-0 mt-1 mr-1"
              onClick={() => setEnterInputVisible(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              key="confirm-2"
              className="btn-primary m-0"
              onClick={handleCodeSubmit}
              disabled={subscriptionValid || !codeValue.length}
            >
              {subscriptionValid ? "Already Subscribed" : "Submit"}
            </button>
          </div>
        </div>
      ) : (
        <div className="animated fadeIn">
          <button
            key="confirm-1"
            className="btn-primary m-0 mt-2"
            onClick={handleSubscribeClick}
            disabled={subscriptionValid}
          >
            {subscriptionValid
              ? "Already Subscribed"
              : hasSomeCompletedIntegration
              ? "Update (+$4.99/mo)"
              : "Start Free trial"}
          </button>
          <div>
            {!subscriptionValid && (
              <button
                key="cancel-1"
                className="btn outline btn-transparent btn--xs m-0 mt-1"
                onClick={handleEnterCodeClick}
              >
                Early Access User?
              </button>
            )}
          </div>
        </div>
      )}
    </OnboardingStep>
  );
}
