import { formatRelative } from "date-fns";
import { JobStatus } from "../types";

export default function StatusIndicator({
  status,
  stoppedAt,
  labelPrefix,
  errorMessage,
}: {
  status?: JobStatus | "loading";
  stoppedAt?: number;
  labelPrefix?: string;
  errorMessage?: React.ReactNode;
}) {
  let statusText: React.ReactNode = "⚪️";
  let statusLabel: React.ReactNode = "not yet updated";
  switch (status) {
    case JobStatus.SUBMITTED:
    case JobStatus.PENDING:
    case JobStatus.RUNNABLE:
    case JobStatus.STARTING:
    case JobStatus.RUNNING: {
      statusText = <span className="animated pulse">🟡</span>;
      statusLabel = "update in progress";
      break;
    }
    case JobStatus.SUCCEEDED: {
      statusText = "🟢";
      statusLabel = `last updated ${
        stoppedAt ? formatRelative(stoppedAt, new Date()) : ""
      }`;
      break;
    }
    case JobStatus.FAILED: {
      statusText = "🔴";
      if (errorMessage) {
        statusLabel = errorMessage;
      } else {
        statusLabel = `update failed ${
          stoppedAt ? formatRelative(stoppedAt, new Date()) : ""
        }`;
      }

      break;
    }

    case "loading": {
      statusText = <span className="animated pulse">⚪️</span>;
      statusLabel = "status loading...";
    }
  }

  return (
    <p className="text-xs m-0 mb-1">
      {statusText}
      <span className="ml-1">
        {labelPrefix} {statusLabel}
      </span>
    </p>
  );
}
