import { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import usePlaid from "../hooks/usePlaid";

export default function PlaidLink() {
  const navigate = useNavigate();

  const handleSuccess = useCallback(() => {
    console.log("link success!");
    navigate("/");
  }, [navigate]);

  const handleExit = useCallback(() => {
    console.log("link exited!");
    navigate("/");
  }, [navigate]);

  const { ready, open, initialize } = usePlaid({
    onSuccess: handleSuccess,
    onExit: handleExit,
  });

  const linkOpened = useRef<boolean>(false);
  const linkInitialized = useRef<boolean>(false);
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const accessToken: string | null = queryParams.get("accessToken");
    const accountSelection: string | null = queryParams.get("accountSelection");

    if (!linkInitialized.current) {
      linkInitialized.current = true;
      initialize({
        plaidAccessToken: accessToken,
        accountSelection: accountSelection === "true",
      });
    }

    if (ready && !linkOpened.current) {
      linkOpened.current = true;
      open();
    }
  });

  return <div style={{ height: 2000 }}></div>;
}
