//   {/* DetectedStocksPanel with a button to scan */}
import StepCard from "./DashboardCard";
import { useCallback, useContext, useEffect } from "react";
import { StockTickersContext } from "../providers/StockTickersProvider";
import { CLIENT_CONFIG } from "../constants";

export default function DetectedStocksCard() {
  const {
    scanWorkspace,
    getItems,
    state: { tickers, loading, lastScanJobId },
  } = useContext(StockTickersContext);
  useEffect(() => {
    getItems();
  }, []);
  const handleScanClick = useCallback(() => {
    scanWorkspace();
  }, [scanWorkspace]);
  return (
    <StepCard
      integrationType="stocks"
      image={
        <CLIENT_CONFIG.integrationConfig.stocks.stocksImages.dashboardDetectedStocksPanel />
      }
      title="Detected Stocks"
      subtitle="Stock tickers detected in your Notion workspace will show up here. We scan periodically for new stocks, but you can also trigger a scan manually."
      buttonContent={
        <>
          <i className="ri-scan-2-line mr-1 text-md"></i>Scan
        </>
      }
      onButtonClick={handleScanClick}
    >
      <>
        {loading ? (
          <div className="animated loading m-2" style={{ height: 40 }}></div>
        ) : tickers.length ? (
          <ul className="no-bullets">
            {tickers.map((ticker) => (
              <li>✅ {ticker.ticker}</li>
            ))}
          </ul>
        ) : (
          <p>
            No tickers detected yet. Try adding pages to your template tickers
            database!
          </p>
        )}
      </>
    </StepCard>
  );
}
