import { useCallback } from "react";
import { IntegrationType, StripeSubscriptionJSON } from "../types";
import DashboardCard from "./DashboardCard";
import { CLIENT_CONFIG } from "../constants";

interface SubscriptionCardProps {
  userSubscription: StripeSubscriptionJSON;
}

export default function PaymentMethodCard({
  userSubscription,
}: SubscriptionCardProps) {
  const handleEditPaymentMethodClick = useCallback(() => {}, []);
  return (
    <DashboardCard
      integrationType={"budget"}
      image={<CLIENT_CONFIG.images.billing.paymentMethodPanel />}
      title="Payment Method"
      subtitle="Make changes to your card on file."
      buttonContent={<>Edit</>}
      onButtonClick={handleEditPaymentMethodClick}
    >
      some context
    </DashboardCard>
  );
}
