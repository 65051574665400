import { useCallback, useContext, useMemo } from "react";
import styled from "styled-components";
import { BatchJobJSON, JobStatus, PlaidItemJSON } from "../types";
import { BatchJobContext } from "../providers/BatchJobsProvider";
import StatusIndicator from "./StatusIndicator";
import { Link, useNavigate } from "react-router-dom";
import DeleteConfirmationModal from "./modals/DeleteConfirmationModal";
import { isJobPolling } from "../utils";

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: visible;
`;

const Col1 = styled.div``;
const Col2 = styled.div`
  display: flex;
  align-items: center;
`;

interface ComponentProps {
  item: PlaidItemJSON;
  deleteItem: (itemId: string) => void;
  hideDivider?: boolean;
}

export default function PlaidItemRow({
  item: {
    institutionName,
    itemId,
    lastTransactionSyncJobId,
    errorCode,
    accessToken,
  },
  deleteItem,
  hideDivider,
}: ComponentProps) {
  const navigate = useNavigate();

  const {
    state: { jobs },
  } = useContext(BatchJobContext);

  const handleDelete = useCallback(
    async () => deleteItem(itemId),
    [itemId, deleteItem]
  );

  const transactionJob: BatchJobJSON | undefined = lastTransactionSyncJobId
    ? jobs[lastTransactionSyncJobId]
    : undefined;

  const errorMessage = useMemo<React.ReactNode>(() => {
    switch (errorCode) {
      case "ITEM_LOGIN_REQUIRED":
      case "PENDING_EXPIRATION": {
        return (
          <span>
            Authorization for this account has expired.
            <Link
              to={{
                pathname: `/plaid-link`,
                search: `?accessToken=${accessToken}`,
              }}
            >
              Click here
            </Link>{" "}
            to re-authorize this account.
          </span>
        );
      }
      case "NO_ACCOUNTS": {
        return "We were unable to detect any accounts for this institution.";
      }
      case "ITEM_NOT_FOUND": {
        return "Unable to retrieve account. Please try removing and add it again.";
      }
      default: {
        return "An error has occurred and we are unable to retrieve data for this account. Please contact support.";
      }
    }
  }, [errorCode, accessToken]);

  const handleReauthClick = useCallback(() => {
    navigate(`/plaid-link?accessToken=${accessToken}&accountSelection=true`);
  }, [navigate, accessToken]);

  const modalId: string = `delete-item-${itemId}`;

  return (
    <>
      <Row className="animated fadeIn">
        <Col1>
          <p className="text-md font-semibold my-1">
            🏦 {institutionName || "Unnamed Institution"}
          </p>
          <div className="pl-2">
            {errorCode ? (
              <StatusIndicator
                status={JobStatus.FAILED}
                errorMessage={errorMessage}
              />
            ) : (
              <StatusIndicator
                status={transactionJob ? transactionJob.status : "loading"}
                stoppedAt={transactionJob?.stoppedAt || undefined}
                labelPrefix="Account data"
              />
            )}
          </div>
        </Col1>
        <Col2>
          <button
            onClick={handleReauthClick}
            className="btn--xs btn-transparent m-0 mr-1 ml-2 tooltip u-center u-inline-flex"
            data-tooltip="Re-authorize"
          >
            <i className="ri-login-circle-line text-md"></i>
          </button>
          <a
            href={`#${modalId}`}
            className="btn--xs btn-transparent m-0 tooltip u-center u-inline-flex px-1"
            data-tooltip="Remove account"
          >
            <i className="ri-delete-bin-line text-md"></i>
          </a>
        </Col2>
      </Row>
      {!hideDivider && <div className="divider m-0" />}
      <DeleteConfirmationModal
        modalId={modalId}
        title="Remove this Account?"
        onConfirm={handleDelete}
      >
        {`Are you sure you want to remove ${institutionName} from NoFi?`}
      </DeleteConfirmationModal>
    </>
  );
}
